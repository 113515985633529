<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col mb-5">
                <a target="_blank" class="btn btn-primary pull-right md-raised md-primary text-light"
                    href="static/pdf/PrivacyTrattamentoDatiModulo.pdf">Scarica modulo</a>
                <h3>INFORMATIVA IN ATTUAZIONE DEL REGOLAMENTO UE 2016/679</h3>
                Ai sensi dell’art. 13 del Regolamento europeo (UE) 2016/679 (di seguito GDPR), e in relazione ai dati
                personali di cui Trifolio s.r.l. (d’ora innanzi: “Titolare del trattamento”) entrerà in possesso, La
                informiamo di quanto segue:<br>
                Titolare del trattamento e responsabile della protezione dei dati personali<br>
                Titolare del trattamento è Trifolio s.r.l in via dei Peschi 10°, 37141 Verona con telefono +39 045
                8841020, con email: <a href="mailto:privacy@trifoliosrl.com">privacy@trifoliosrl.com</a> e con email pec
                <a href="mailto:trifolio@mailgarantita.it">trifolio@mailgarantita.it</a><br>
                Il Titolare non ha nominato un responsabile della protezione dei dati personali (RPD ovvero, Data
                Protection Officer, DPO).<br>
                <h4>CATEGORIE DI DATI OGGETTO DEL TRATTAMENTO</h4>
                I dati personali forniti dall’utente/cliente e previo, consenso esplicito, soggetti a trattamento sono
                dati c.d. “comuni” e riguardano dati anagrafici quali nome, cognome, indirizzo fisico, nazionalità,
                provincia
                e comune di residenza o di sede legale (per le persone giuridiche), telefono fisso o mobile, codice
                fiscale, indirizzi email, dati fiscali e contabili per la fatturazione. Le seguenti tipologie di dati
                elencati saranno oggetto di trattamento improntato ai principi di correttezza, liceità, trasparenza e
                di tutela della riservatezza e dei diritti dell’utente/cliente.<br>
                <h4>Finalità del trattamento dei dati</h4>
                I dati personali dell’utente/cliente sono raccolti e trattati per l’invio di informazioni commerciali,
                promozionali e/o amministrative, anche di altre Società, per aggiornamenti sulle nostre offerte ovvero
                per finalità connesse agli obblighi previsti da leggi, da regolamenti e dalla normativa comunitaria
                nonché da disposizioni impartite da autorità a ciò legittimate dalla legge e da organi di vigilanza e
                controllo.<br>
                <h4>Modalità del trattamento dei dati</h4>
                II trattamento è realizzato per mezzo delle operazioni indicate all’art. 4 GDPR: raccolta,
                registrazione, organizzazione, strutturazione, conservazione, adattamento o modifica, estrazione,
                consultazione, uso, comunicazione, trasmissione, diffusione, o qualsiasi altra forma di messa a
                disposizione, raffronto o interconnessione, limitazione, cancellazione e distruzione dei dati.<br>
                Le operazioni possono essere svolte con o senza l’ausilio di strumenti elettronici o comunque
                automatizzati. II trattamento è svolto dal Titolare o dai Responsabili esterni o dagli autorizzati al
                trattamento. I dati personali sono trattati con modalità strettamente necessarie a far fronte alle
                finalità sopra indicate.<br>
                <h4>Base giuridica del trattamento</h4>
                Il Titolare del trattamento tratta i Suoi dati personali lecitamente, laddove il trattamento:<br>
                • sia necessario all’esecuzione di un ordine o di un contratto di cui Lei è parte o all’esecuzione di
                misure precontrattuali adottate su richiesta;<br>
                • sia necessario per adempiere un obbligo legale incombente sul Titolare del trattamento.<br>
                <h4>Conseguenze della mancata comunicazione dei dati personali</h4>
                Con riguardo ai dati personali relativi all’esecuzione del contratto di cui Lei è parte o relativi
                all’adempimento ad un obbligo normativo (ad esempio gli adempimenti legati alla tenuta delle scritture
                contabili e fiscali), la mancata comunicazione dei dati personali impedisce il perfezionarsi del
                rapporto contrattuale stesso.<br>
                <h4>Trasferimento dati a Paesi terzi</h4>
                La gestione e la conservazione dei dati personali, avverrà su server ubicati all’interno dell’Unione
                Europea, di proprietà del Titolare del trattamento o di società terze incaricate e debitamente
                nominate quali Responsabili del trattamento. Resta inteso che il Titolare del trattamento, ove si
                rendesse necessario, avrà facoltà di spostare l’ubicazione dei server in Italia e/o Unione Europea e/o
                Paesi extra- UE. I dati personali potrebbero essere oggetto di trasferimento anche extra-UE verso
                soggetti con
                cui è in corso una collaborazione per l’instaurazione e gestione dell’incarico ricevuto dal Titolare
                del trattamento. In tal caso, il Titolare del trattamento assicura sin d’ora che il trasferimento dei
                dati extra- UE avverrà in conformità alle disposizioni di legge applicabili, stipulando, se
                necessario, accordi che garantiscano un livello di protezione adeguato, e/o adottando le clausole
                contrattuali standard previste dalla Commissione Europea. In ogni caso, si precisa sin d’ora che il
                Titolare del trattamento non cederà mai a terzi i dati personali raccolti.<br>
                <h4>Conservazione dei dati</h4>
                I Suoi dati personali, oggetto di trattamento per le finalità sopra indicate, saranno conservati per il
                periodo di durata del contratto e, successivamente, per il tempo in cui il Titolare del trattamento sia
                soggetto a obblighi di conservazione per finalità fiscali o per altre finalità, previsti da norme di
                legge o regolamento.<br>
                <h4>Comunicazione dei dati</h4>
                I Suoi dati personali potranno essere comunicati a:<br>
                1. consulenti, commercialisti o legali che eroghino prestazioni funzionali ai fini sopra indicati;<br>
                2. istituti bancari e assicurativi che eroghino prestazioni funzionali ai fini sopra indicati;<br>
                3. soggetti che elaborano i dati in esecuzione di specifici obblighi di legge;<br>
                4. Autorità giudiziarie o amministrative, per l’adempimento degli obblighi di legge.<br>
                Profilazione e Diffusione dei dati<br>
                I Suoi dati personali non sono soggetti a diffusione né ad alcun processo decisionale interamente
                automatizzato, ivi compresa la profilazione.<br>
                <h4>Diritti dell’interessato</h4>
                Tra i diritti a Lei riconosciuti dal GDPR rientrano quelli di:<br>
                • chiedere al Titolare del trattamento l’accesso ai Suoi dati personali ed alle informazioni relative
                agli stessi; la rettifica dei dati inesatti o l’integrazione di quelli incompleti; la cancellazione dei
                dati personali che La riguardano (al verificarsi di una delle condizioni indicate nell’art. 17,
                paragrafo 1 del GDPR e nel rispetto delle eccezioni previste nel paragrafo 3 dello stesso articolo); la
                limitazione del trattamento dei Suoi dati personali (al ricorrere di una delle ipotesi indicate
                nell’art. 18, paragrafo 1 del GDPR);<br>
                • richiedere ed ottenere dal Titolare del trattamento – nelle ipotesi in cui la base giuridica del
                trattamento sia il contratto o il consenso, e lo stesso sia effettuato con mezzi automatizzati – i Suoi
                dati personali in un formato strutturato e leggibile da dispositivo automatico, anche al fine di
                comunicare tali dati ad un altro titolare del trattamento (c.d. diritto alla portabilità dei dati
                personali);<br>
                • opporsi in qualsiasi momento al trattamento dei Suoi dati personali al ricorrere di situazioni
                particolari che La riguardano;<br>
                • revocare il consenso in qualsiasi momento, limitatamente alle ipotesi in cui il trattamento<br>
                sia basato sul Suo consenso per una o più specifiche finalità e riguardi dati personali comuni (ad
                esempio data e luogo di nascita o luogo di residenza), Il trattamento basato sul consenso ed effettuato
                antecedentemente alla revoca dello stesso conserva, comunque, la sua liceità;<br>
                • proporre reclamo a un’autorità di controllo (Autorità Garante per la protezione dei dati personali –
                www.garanteprivacy.it).<br>
                Per quanto non espressamente previsto dalle disposizioni quivi richiamate si rimanda integralmente alla
                normativa in vigore in tema privacy.<br>
                L’interessato esercita i suoi diritti scrivendo al Titolare del trattamento all’indirizzo riportato al
                punto 1 della presente informativa, oppure tramite l’indirizzo email <a
                    href="mailto:privacy@trifoliosrl.com">privacy@trifoliosrl.com</a> , specificando l’oggetto della sua
                richiesta, il diritto che intende esercitare e allegando fotocopia di un documento di identità che
                attesti la legittimità della richiesta.<br>
                Il consenso esplicito al trattamento dei dati così come indicato nella presente informativa
                sottoscritta ai sensi dell’art. 13 del Regolamento UE n.679/2016, con la sottoscrizione del presente
                modulo, l’interessato (utente/cliente) acconsente ai sensi e per gli effetti dell’art. 7 e ss. del
                Regolamento UE n.679/2016,
                al trattamento dei dati personali secondo le modalità e nei limiti indicati all’interno della presente
                informativa.<br>
                In assenza di firma, procederemo comunque al trattamento dei dati necessari per le finalità
                contrattuali, essendo per esse sufficiente l’informativa della finalità e, considerando l’attuale
                normativa, lecito il trattamento a prescindere dal consenso prestato.<br><br>
                I nostri più cordiali saluti,<br><br>
                TRIFOLIO s.r.l.
            </div>
        </div>
        <div class="clearfix separator"></div>
    </div>
</template>
<script>
export default {
    name: 'TrattamentoDati',
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Trattamento Dati | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut',
        link: [
            {
                rel: 'canonical',
                href: 'https://trifoliosrl.com/trattamento-dati'
            }
        ],
    }
}
</script>
