<template id="page">
    <div id="app" :class="pname">
        <AppHeader :navItems="nav" />
        <div class='easytransitions_transition'>
            <div class='div easytransitions_transition__part-1' :class="transitionClass"></div>
            <div class='div easytransitions_transition__part-4' :class="transitionClass"></div>
        </div>
        <main>
            <transition v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:after-enter="afterEnter" name="fade"
                mode="out-in" v-on:appear="enter">
                <router-view></router-view>
            </transition>
        </main>
        <AppFooter />
    </div>
</template>

<script>
import nav from '@/nav'
import { MainHeader as AppHeader, MainFooter as AppFooter } from '@/components/'
export default {
    name: 'App',
    components: {
        AppHeader,
        AppFooter
    },
    data () {
        return {
            pname: '',
            transitionClass: 'none',
            nav: nav.items
        }
    },
    methods: {
        afterEnter () {
            var _this = this
            setTimeout(function () {
                _this.transitionClass = false
            }, 1000)
        },
        enter () {
            if (this.$route.query.lang) this.language = this.$route.query.lang
            this.pname = this.$route.name
        },
        beforeLeave () {
            this.transitionClass = 'split_diagonal'
        }
    },
    computed: {
        metaDescription () {
            return this.language === 'it' ? 'Trifolio è uno stampatore veronese all’avanguardia nei servizi di prestampa e stampa per libri d’arte e fotografia. Unisce le più recenti tecnologie industriali alla sapiente maestria della bottega artigianale di tradizione. Ogni libro è una esperienza nuova condotta con metodo di lavoro a regola d’arte.' : 'Trifolio is a Verona-based printer offering cuting-edge pre-press and printing service for art and photography books. It unites industrial technologies with the craftsmanship and skill of the traditional artisan print shop.'
        }
    },
    metaInfo () {
        return {
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                }
            ]
        }
    }
}
</script>
