<template>
    <b-row>
        <b-col>
            <router-link :to="{name: 'Single', params: {'id': commessa.id}}">
                <h3>{{commessa.title}}</h3>
            </router-link>
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: {
        commessa: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
