<template>
    <b-navbar id="masthead" fixed="top" toggleable="md">
        <b-navbar-brand>
            <router-link class="dark-logo nav-link" @click.native="toggleMenu" to="/">
                <img src="static/images/Logo_Trifolio.svg" alt="Trifolio">
            </router-link>
            <router-link class="light-logo nav-link" @click.native="toggleMenu" to="/">
                <img src="static/images/Logo_Trifolio_light.svg" alt="Trifolio">
            </router-link>
        </b-navbar-brand>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-collapse is-nav id="nav_collapse" v-model="showCollapse">
            <b-navbar-nav class="ml-auto">
                <li class="nav-item">
                    <a :href="history">
                        <span class="nav-link-text magic">Magic</span>
                    </a>
                </li>
                <li v-for="(item, index) in navItems" :key="index" :class="{ active: $route.name === item.name }"
                    class="nav-item">
                    <router-link class="nav-link" :to="item.url" @click.native="toggleMenu">
                        <span v-if="language === 'it'" class="nav-link-text">
                            {{ item.name.it }}
                        </span>
                        <span v-else class="nav-link-text">
                            {{ item.name.en }}
                        </span>
                    </router-link>
                </li>
                <li :class="{ active: language === 'it' }" class="nav-item lang">
                    <a href="#" @click="language = 'it'">IT</a>
                </li>
                <li :class="{ active: language === 'en' }" class="nav-item lang">
                    <a href="#" @click="language = 'en'">EN</a>
                </li>
                <li v-if="isAuthenticated" class="nav-item">
                    <a href="#" @click="logout()">{{ currentUser.username }} <i class="fa fa-sign-out"></i></a>
                </li>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>
<script>
import {
    mapGetters
} from 'vuex'
import {
    LOGOUT
} from '@/store/actions.type'
export default {
    name: 'main-header',
    props: {
        navItems: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data () {
        return {
            showCollapse: false
        }
    },
    computed: {
        ...mapGetters([
            'isAuthenticated',
            'currentUser'
        ]),
        history () {
            return this.language === 'it' ? 'https://trifoliosrl.com/trifolio-story/it/' : 'https://trifoliosrl.com/trifolio-story/en/'
        }
    },
    watch: {
        language () {
            this.toggleMenu()
        }
    },
    methods: {
        toggleMenu: function () {
            this.showCollapse = false
        },
        logout: function () {
            this.$store.dispatch(LOGOUT)
            this.$router.push('/')
        }
    }
}
</script>
