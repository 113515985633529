<template>
    <div id="portfolio_wrapper" :class="{ loading: isLoading }" style="margin-bottom:200px">
        <div class="main-loader">
            <img src="static/images/solo_logo.svg" />
        </div>
        <div class="portfolio-archive container-fluid">
            <section v-if="commesse" id="filters">
                <div class="row">
                    <div class="col-md-4 col-lg-2 text-center">
                        <h4 v-lang.book></h4>
                        <v-select v-model="listConfig.fbook" :options="books"></v-select>
                    </div>
                    <div class="col-md-4 col-lg-2 text-center">
                        <h4 v-lang.museum></h4>
                        <v-select v-model="listConfig.fmuseum" :options="museum"></v-select>
                    </div>
                    <div class="col-md-4 col-lg-2 text-center">
                        <h4 v-lang.gallery></h4>
                        <v-select v-model="listConfig.fgallery" :options="galleries"></v-select>
                    </div>
                    <div class="col-md-4 col-lg-2 text-center">
                        <h4 v-lang.publisher></h4>
                        <v-select v-model="listConfig.fpublisher" :options="publishers"></v-select>
                    </div>
                    <div class="col-md-4 col-lg-2 text-center">
                        <h4 v-lang.designer></h4>
                        <v-select v-model="listConfig.fdesigner" :options="designers"></v-select>
                    </div>
                    <div class="col-md-4 col-lg-2 text-center">
                        <h4 v-lang.artist></h4>
                        <v-select v-model="listConfig.fartist" :options="artists"></v-select>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-md-4 text-center">
            <h4 v-lang.customer></h4>
            <v-select v-model="listConfig.fclient" :options="clients"></v-select>
          </div> -->
                    <div class="col-md-12">
                        <h4 v-lang.searchtitle></h4>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model="searchText">
                            <div class="input-group-append">
                                <button @click="fetchCommesse()" class="btn btn-outline-secondary" type="button"><i
                                        class="fa fa-search"></i></button>
                                <button @click="resetText()" class="btn btn-outline-secondary" type="button"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="portfolio">
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-xxl-3 col-xxxl-2" v-for="(portfolio, index) in commesse" :key="index">
                        <div :id="`portfolio-grid-item-${portfolio.id}`" class="card">
                            <a @click="openDetail(portfolio)" href="javascript:;">
                                <div class="card-bg-image"
                                    v-bind:style="{ backgroundImage: 'url(' + getFeatured(portfolio.medias) + ')' }">
                                    <div class="title-mask">
                                        <span class="card-title transition">
                                            <p class="year">{{ portfolio.year }}</p>
                                            <h5>{{ portfolio.title }}</h5>
                                            <small v-if="portfolio.client">{{ portfolio.client.name }}</small>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <transition name="portfolio" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import {
    mapGetters
} from 'vuex'
import {
    FETCH_COMMESSE,
    FETCH_CONFIGS,
    FETCH_CONTACTS,
    PORTFOLIO_RESET_STATE
} from '@/store/actions.type'
import {
    RESET_CLIENT_FROM_MAP
} from '@/store/mutations.type'
import {
    MEDIA_PATH
} from '@/common/config'
export default {
    name: 'PortofolioPage',
    components: {
        vSelect
    },
    data () {
        return {
            currentPage: 1,
            designers: [],
            publishers: [],
            galleries: [],
            museum: [],
            artists: [],
            books: [],
            clients: [],
            sf: {},
            searchText: '',
            showModal: this.$route.meta.showModal,
            bottom: false
        }
    },
    computed: {
        totalPages () {
            return Math.round(parseFloat(this.count / 48))
        },
        ...mapGetters([
            'isLoading',
            'commesse',
            'count',
            'configs',
            'contacts',
            'listConfig',
            'currentClient'
        ]),
        metaDescription () {
            return this.language === 'it' ? 'Le storie di chi si è affidato alla maestria di Trifolio, dai nostri servizi di stampa a quelli di rilegatura e cura di immagini e colori.' : 'The stories of those who have relied on Trifolio\'s craftsmanship, from our printing services to our binding and image and colour care.'
        },
        metaTitle () {
            return this.language === 'it' ? 'I nostri lavori' : 'Our works'
        }
    },
    created () {
        window.addEventListener('scroll', () => {
            this.bottom = this.bottomVisible()
        })
    },
    watch: {
        '$route.meta' ({
            showModal
        }) {
            this.showModal = showModal
        },
        language () {
            this.setConfig()
        },
        bottom (bottom) {
            if (bottom) {
                if (this.currentPage < this.totalPages + 1) {
                    this.currentPage++
                    this.fetchCommesse()
                }
            }
        },
        listConfig: {
            handler: function () {
                this.$store.commit(RESET_CLIENT_FROM_MAP)
                this.currentPage = 1
                this.$store.dispatch(PORTFOLIO_RESET_STATE)
                this.fetchCommesse()
            },
            deep: true
        }
    },
    mounted () {
        this.$store.dispatch(PORTFOLIO_RESET_STATE)
        this.fetchCommesse()
        this.fetchConfigs()
        this.fetchContacts()
    },
    methods: {
        bottomVisible () {
            const scrollY = window.scrollY
            const visible = document.documentElement.clientHeight
            const pageHeight = document.documentElement.scrollHeight
            const bottomOfPage = visible + scrollY >= pageHeight
            return bottomOfPage || pageHeight < visible
        },
        resetText () {
            this.searchText = ''
            this.fetchCommesse()
        },
        filterSelects (obj) {
            var ret = {}
            for (const prop in obj) {
                if (obj[prop]) {
                    if (obj[prop].value) {
                        ret[prop] = obj[prop].value
                    }
                }
            }
            return ret
        },
        fetchCommesse () {
            if (this.sf.ftitlelike !== this.searchText) this.$store.dispatch(PORTFOLIO_RESET_STATE)
            this.sf = this.filterSelects(this.listConfig)
            if (this.currentClient) {
                if (this.currentClient.isArtista) this.sf.fartist = this.currentClient.id
                if (this.currentClient.isDesigner) this.sf.fdesigner = this.currentClient.id
                if (this.currentClient.isGallery) this.sf.fgallery = this.currentClient.id
                if (this.currentClient.isPublisher) this.sf.fpublisher = this.currentClient.id
                if (this.currentClient.isMuseum) this.sf.fmuseum = this.currentClient.id
            }
            this.sf.ftitlelike = this.searchText
            this.sf.fpublic = true
            this.sf.page = this.currentPage
            this.sf.limit = 48
            this.$store.dispatch(FETCH_COMMESSE, this.sf)
        },
        fetchConfigs () {
            var _this = this
            this.$store.dispatch(FETCH_CONFIGS).then(() => {
                _this.setConfig()
            })
        },
        fetchContacts () {
            var _this = this
            this.$store.dispatch(FETCH_CONTACTS).then(() => {
                _this.setContatti()
            })
        },
        setContatti () {
            this.designers = this.contacts.filter((user) => {
                return user.isDesigner
            })
            this.designers = this.seletcIze(this.designers, 'name')
            this.publishers = this.contacts.filter((user) => {
                return user.isPublisher
            })
            this.publishers = this.seletcIze(this.publishers, 'name')
            this.museum = this.contacts.filter((user) => {
                return user.isMuseum
            })
            this.museum = this.seletcIze(this.museum, 'name')
            this.artists = this.contacts.filter((user) => {
                return user.isArtista
            })
            this.artists = this.seletcIze(this.artists, 'name')
            this.galleries = this.contacts.filter((user) => {
                return user.isGallery
            })
            this.galleries = this.seletcIze(this.galleries, 'name')
            this.clients = this.contacts.filter((user) => {
                return user.isClient
            })
            this.clients = this.seletcIze(this.clients, 'name')
        },
        seletcIze (obj, $param) {
            var seletcIzed = []
            for (var o = 0; o < obj.length; o++) {
                seletcIzed.push({
                    value: obj[o].id,
                    label: obj[o][$param]
                })
            }
            return seletcIzed
        },
        setConfig () {
            this.books = this.configs.filter(function (c) {
                return c.type === 'book'
            })
            if (this.language === 'it') {
                this.books = this.seletcIze(this.books, 'name')
            } else {
                this.books = this.seletcIze(this.books, 'nameEn')
            }
        },
        getFeatured (gallery) {
            if (gallery.length) {
                var feat = gallery.filter(function (f) {
                    return f.isDefault
                })
                if (feat.length > 0) {
                    return MEDIA_PATH + feat[0].source
                } else {
                    return MEDIA_PATH + 'default-image.jpg'
                }
            } else {
                return MEDIA_PATH + 'default-image.jpg'
            }
        },
        findParam (id, type) {
            if (type === 'config') {
                this.configs.filter(function (c) {
                    return c.id === id
                })
            }
            if (type === 'contact') {
                this.contacts.filter(function (c) {
                    return c.id === id
                })
            }
        },
        openDetail (portfolio) {
            this.$router.push({
                name: 'Single',
                params: {
                    id: portfolio.id
                }
            })
        }
    },
    beforeRouteUpdate (from, to, next) {
        // if (to.params.id) {
        //     var elmnt = document.getElementById(`portfolio-grid-item-${to.params.id}`)
        //     setTimeout(() => {
        //         if (elmnt) elmnt.scrollIntoView()
        //     }, 100)
        // }
        next()
    },
    metaInfo () {
        return {
            title: this.metaTitle,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://trifoliosrl.com/portfolio'
                }
            ],
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                }
            ]
        }
    }
}
</script>
