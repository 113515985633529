export default {
    about: 'Chi siamo',
    ao: 'Responsabile amministrativo',
    pm: 'Responsabile di prestampa',
    csm: 'Responsabile clienti',
    beauty: 'Bellezza',
    contactUs: 'Contattaci',
    message: 'Messaggio',
    send: 'Invia',
    company: 'Azienda',
    name: 'Nome',
    telephone: 'Telefono',
    department: 'Reparto',
    calendar: 'Calendario',
    title: 'Titolo',
    year: 'Anno',
    access: 'Accesso',
    manager: 'Responsabile del progetto',
    searchtitle: 'Cerca per titolo',
    worktitle: 'Titolo portfolio',
    grm: 'grm2',
    customer: 'Cliente',
    publisher: 'Editore',
    contact: 'Contatti',
    designer: 'Grafico',
    width: 'Base',
    height: 'Altezza',
    pages: 'Pagine',
    paper: 'Carta',
    binding: 'Rilegatura',
    book: 'Libro',
    museum: 'Museo',
    gallery: 'Galleria',
    artist: 'Artista',
    quote: '“ Il tempo degli eventi è diverso dal nostro. ”',
    code: 'Commessa',
    progressive: 'Progressivo',
    category: 'Categoria',
    edition: 'Tiratura',
    notes: 'Note',
    bindingNotes: 'Note legatoria',
    bookType: 'Tipo di libro',
    signature: 'Composizione segnature',
    imagesType: 'Tipo di immagini',
    quantity: 'Quantità',
    lineScreen: 'Lineatura',
    photolithography: 'Fotolito',
    film: 'Pellicole',
    test: 'Prove',
    print: 'Stampe',
    bindery: 'Legatoria',
    papermaking: 'Cartotecnica',
    plastification: 'Plastificazione',
    paperType: 'Tipo di carta',
    internalFirst: 'Carta Interno',
    internalSecond: 'Carta Interno 1',
    internalThird: 'Carta Interno 2',
    flyleaf: 'Risguardi',
    cover: 'Copertina',
    dustJacket: 'Sovracopertina',
    cardboard: 'Cartone',
    canvas: 'Tela',
    shearing: 'Trancia',
    case: 'Astuccio',
    paperbackQty: 'Copie Brossura',
    cardboardQty: 'Copie Cartone',
    shelf: 'Scaffale',
    details: 'Dettagli',
    dataSheet: 'Specifiche tecniche',
    providers: 'Fornitori',
    archive: 'Archivio',
    materials: 'Materiali',
    format: 'Formato',
    history: 'Storia',
    privacyForm: 'Ho letto e accetto <a href="https://www.iubenda.com/privacy-policy/38480994" target="_blank">l\'informativa</a> sulla privacy e il trattamento dei miei dati personali',
    marketingForm: 'Presto il mio consenso al trattamento dei miei dati personali per la ricezione di newsletter, comunicazioni promozionali e per finalità  di profilazione ed analisi da parte di Trifolio srl',
    nextworks: 'Prossimi lavori',
    worldText1: 'Trifolio ha lavorato e sta lavorando per molti clienti sparsi nel mondo. Clicca sui ‘target’, riuscirai così vedere il nome dei clienti ed i titoli che Trifolio ha stampato per loro.',
    worldText2: '«Il mondo è un libro, e chi non viaggia ne legge solo una pagina.»',
    worldText2Cit: 'Agostino d\'Ippona',
    beautyText: 'La bellezza.. La bellezza salverà il mondo, una frase forte e piena di speranza, alla quale Trifolio crede intensamente. Un po’ per gioco ma soprattutto perché ne siamo convinti, abbiamo chiesto a clienti, visitatori e amici di contribuire con una frase al “Muro della bellezza.” Il risultato è entusiasmante, se vieni a trovarci potrai arricchirci con la tua frase.'
}
