export default {
    about: 'About',
    ao: 'Administrative officer',
    pm: 'Prepress manager',
    csm: 'Customer service manager',
    beauty: 'Bellezza',
    contactUs: 'Contact us',
    message: 'Message',
    send: 'Send',
    company: 'Company',
    name: 'Name',
    telephone: 'Telephone',
    department: 'Department',
    calendar: 'Calendar',
    title: 'Title',
    year: 'Year',
    access: 'Access',
    manager: 'Production Manager',
    searchtitle: 'Search by title',
    worktitle: 'Portfolio title',
    grm: 'gsm',
    customer: 'Customer',
    publisher: 'Publisher',
    contact: 'Contacts',
    designer: 'Designer',
    width: 'Width',
    height: 'Height',
    pages: 'Pages',
    paper: 'Paper',
    binding: 'Binding',
    book: 'Book',
    museum: 'Museum',
    gallery: 'Gallery',
    artist: 'Artist',
    quote: '“ Il tempo degli eventi è diverso dal nostro. ”',
    code: 'Code',
    progressive: 'N°',
    category: 'Category',
    edition: 'Number of copies',
    notes: 'Notes',
    bindingNotes: 'Binding\'s Notes',
    bookType: 'Orientation',
    signature: 'Signatures',
    imagesType: 'Kind of separations',
    quantity: 'Quantity',
    lineScreen: 'Line Screen',
    photolithography: 'Photolithography',
    film: 'Film',
    test: 'Test',
    print: 'Print',
    bindery: 'Binding',
    papermaking: 'Papermaking',
    plastification: 'Lamination',
    paperType: 'Kind of paper',
    internalFirst: 'Paper',
    internalSecond: 'Paper 1',
    internalThird: 'Paper 2',
    flyleaf: 'Endleaves',
    cover: 'Cover',
    dustJacket: 'Dust Jacket',
    cardboard: 'Board',
    canvas: 'Cloth',
    shearing: 'Foil',
    case: 'Slipcase',
    paperbackQty: 'Paperback Copies',
    cardboardQty: 'Cardboard Copies',
    shelf: 'Shelf',
    details: 'Details',
    dataSheet: 'Data Sheet',
    providers: 'Providers',
    archive: 'Archive',
    materials: 'Materials',
    format: 'Size',
    history: 'History',
    nextworks: 'Upcoming press dates',
    privacyForm: 'I have read and accept the <a href="https://www.iubenda.com/privacy-policy/38480994" target="_blank">privacy policy</a> and the processing of my personal data.',
    marketingForm: 'I give my consent to the processing of my personal data for the receipt of newsletters, promotional communications and for profiling and analysis purposes by Trifolio srl',
    worldText1: 'Trifolio works with clients from around the world. Click on the icons to see our customers and the titles we have printed for them.',
    worldText2: '«The world is a book and those who do not travel read only one page»',
    worldText2Cit: 'Agostino d\'Ippona',
    beautyText: 'Beauty.. Beauty will save the world, a strong and hopeful phrase, which Trifolio whole-heartedly believes. From this sincere conviction—but with a playful spirit—we asked customers, visitors and friends to contribute a sentence to our “Wall of Beauty." The result is intriguing and exciting. If you come to visit us, you can enrich us further with your own thoughts.'
}
