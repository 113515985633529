import ApiService from '@/common/api.service'
import JwtService from '@/common/jwt.service'
import {
    LOGIN,
    LOGOUT,
    CHECK_AUTH
} from './actions.type'
import {
    SET_AUTH,
    RE_SET_AUTH,
    PURGE_AUTH,
    SET_ERROR
} from './mutations.type'

const state = {
    user: {},
    errors: null,
    userid: JwtService.getUserId(),
    userType: JwtService.getUserType(),
    isAuthenticated: !!JwtService.hasToken()
}

const getters = {
    currentUser (state) {
        return state.user
    },
    currentUserId (state) {
        return state.userid
    },
    currentUserType (state) {
        return state.userType
    },
    isAuthenticated (state) {
        return state.isAuthenticated
    }
}

const actions = {
    [LOGIN] (context, credentials) {
        return new Promise((resolve) => {
            ApiService
                .post('login', credentials)
                .then(({
                    data
                }) => {
                    context.commit(SET_AUTH, data)
                    resolve(data)
                })
                .catch(function (error) {
                    context.commit(SET_ERROR, error)
                })
        })
    },
    [LOGOUT] (context) {
        context.commit(PURGE_AUTH)
    },
    [CHECK_AUTH] (context) {
        if (JwtService.hasToken()) {
            ApiService.setHeader()
            ApiService
                .get('users/active')
                .then(({
                    data
                }) => {
                    context.commit(RE_SET_AUTH, data)
                })
        } else {
            context.commit(PURGE_AUTH)
        }
    }
}

const mutations = {
    [SET_ERROR] (state, error) {
        state.errors = error.response
    },
    [SET_AUTH] (state, user) {
        state.isAuthenticated = true
        state.userid = user.user.contact.id
        state.userType = user.user.type
        state.user = user
        state.errors = {}
        JwtService.saveToken(user)
    },
    [RE_SET_AUTH] (state, user) {
        state.userid = user.contact.id
        state.userType = user.type
        state.user = user
    },
    [PURGE_AUTH] (state) {
        state.isAuthenticated = false
        state.user = {}
        state.errors = {}
        JwtService.destroyToken()
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
