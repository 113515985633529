const ID_TOKEN_KEY = 'tfl_token'
const ID_USER_KEY = 'tfl_user'
const USER_TYPE = 'tfl_user_tpe'

export default {
    hasToken () {
        return window.localStorage.getItem(ID_TOKEN_KEY) && window.localStorage.getItem(ID_USER_KEY)
    },
    getToken () {
        return window.localStorage.getItem(ID_TOKEN_KEY)
    },
    getUserId () {
        return window.localStorage.getItem(ID_USER_KEY)
    },
    getUserType () {
        return window.localStorage.getItem(USER_TYPE)
    },
    saveToken (user) {
        window.localStorage.setItem(ID_TOKEN_KEY, user.token)
        window.localStorage.setItem(ID_USER_KEY, user.user.contact.id)
        window.localStorage.setItem(USER_TYPE, user.user.type)
    },
    destroyToken () {
        window.localStorage.removeItem(ID_TOKEN_KEY)
        window.localStorage.removeItem(ID_USER_KEY)
        window.localStorage.removeItem(USER_TYPE)
    }
}
