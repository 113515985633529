<template>
    <section class="world">
        <gmap-map ref="map" :options="options" :zoom="3" :center="{
            lat: 33.237756,
            lng: -41.761760
        }" style="width: 100%; height: 100%; position: absolute; z-index: 123">
            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                @closeclick="infoWinOpen = false">
                <a href="javascript:;" @click="setClient" v-html="infoContent"></a>
            </gmap-info-window>
            <gmap-cluster :minimumClusterSize="10">
                <gmap-marker v-for="(m, index) in markers" :key="index" :position="m.latLng" :clickable="true"
                    @click="toggleInfoWindow(m, index)">
                </gmap-marker>
            </gmap-cluster>
        </gmap-map>
        <span class="world-left-quote">
            <h3 v-lang.worldText1 />
        </span>
        <span class="world-right-quote">
            <h3 v-lang.worldText2 />
            <span v-lang.worldText2Cit />
        </span>
    </section>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/markerclustererplus/2.1.4/markerclusterer.js"></script>
<script>
// import axios from 'axios'
import _MAPSTYLE from '../../static/json/defaultMap.json'
import Vue from 'vue'
import {
    mapGetters
} from 'vuex'
import {
    FETCH_CONTACTS
} from '@/store/actions.type'
import {
    SET_CLIENT_FROM_MAP
} from '@/store/mutations.type'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA56h4Tjc7m7_5xuP507oTXA2gwFmFuLi8'
    }
})
export default {
    name: 'WorldPage',
    data () {
        return {
            markers: [],
            infoContent: '',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            currentClient: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            options: {
                scrollwheel: false,
                navigationControl: true,
                mapTypeControl: false,
                scaleControl: true,
                styles: _MAPSTYLE
            }
        }
    },
    components: {
        'gmap-cluster': VueGoogleMaps.Cluster
    },
    computed: {
        ...mapGetters([
            'contacts',
            'listConfig'
        ]),
        metaDescription () {
            return this.language === 'it' ? 'In Trifolio curiamo progetti artistici provenienti da tutto il mondo, nel rispetto delle esigenze e dell\'arte di ciascuno dei nostri clienti.' : 'At Trifolio, we curate art projects from around the world, always respectful of the needs and artistry of each of our clients.'
        },
        metaTitle () {
            return this.language === 'it' ? 'Trifolio nel Mondo' : 'Trifolio in the World'
        }
    },
    mounted () {
        // this.fetchMapConfig()
        this.fetchContacts()
    },
    methods: {
        fetchContacts () {
            var _this = this
            this.$store.dispatch(FETCH_CONTACTS).then(function (data) {
                _this.setMarkers()
            })
        },
        setMarkers () {
            let _markers = this.contacts.filter(function (contact) {
                return (contact.mapLat !== null && contact.mapLong !== null)
            })
            for (var i = 0; i < _markers.length; i++) {
                this.markers.push({
                    latLng: {
                        lat: parseFloat(_markers[i].mapLat),
                        lng: parseFloat(_markers[i].mapLong)
                    },
                    infoText: _markers[i],
                    // icon: require('@/assets/logo.png')
                    // icon: require('@/../static/images/trifolio-marker.png')
                })
            }
        },
        toggleInfoWindow (marker, idx) {
            this.infoWindowPos = marker.latLng
            this.currentClient = marker.infoText
            this.infoContent = marker.infoText.name
            if (this.currentMidx === idx) {
                this.infoWinOpen = !this.infoWinOpen
            } else {
                this.infoWinOpen = true
                this.currentMidx = idx
            }
        },
        setClient () {
            this.$store.commit(SET_CLIENT_FROM_MAP, this.currentClient)
            this.$router.push('portfolio')
        }
    },
    metaInfo () {
        return {
            title: this.metaTitle,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://trifoliosrl.com/world'
                }
            ],
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                }
            ]
        }
    }
}
</script>
