// import Vue from 'vue'
import {
    PortfolioService,
    ConfigsService,
    ContactsService
} from '@/common/api.service'
import {
    FETCH_COMMESSE,
    FETCH_COMMESSA,
    FETCH_CONFIGS,
    FETCH_CONTACTS,
    PORTFOLIO_RESET_STATE
} from './actions.type'
import {
    FETCH_START,
    SET_CONFIGS,
    SET_CONTACTS,
    SET_COMMESSA,
    SET_COMMESSE,
    RESET_STATE,
    SET_CLIENT_FROM_MAP,
    RESET_CLIENT_FROM_MAP
} from './mutations.type'

const initialState = {
    listConfig: {
        fpublic: true,
        page: 1,
        limit: 48,
        fbook: null,
        fclient: null,
        fmuseum: null,
        fgallery: null,
        fpublisher: null,
        fdesigner: null,
        fartist: null,
        ftitlelike: null
    },
    currentClient: null,
    count: 0,
    configs: [],
    contacts: [],
    commesse: [],
    commessa: {},
    isLoading: true
}

export const state = Object.assign({}, initialState)

const getters = {
    listConfig (state) {
        return state.listConfig
    },
    currentClient (state) {
        return state.currentClient
    },
    commessa (state) {
        return state.commessa
    },
    commesse (state) {
        return state.commesse
    },
    count (state) {
        return state.count
    },
    contacts (state) {
        return state.contacts
    },
    configs (state) {
        return state.configs
    },
    isLoading (state) {
        return state.isLoading
    }
}

const actions = {
    [FETCH_COMMESSE] ({
        commit
    }, params) {
        commit(FETCH_START)
        return PortfolioService.query(params)
            .then(({
                data
            }) => {
                commit(SET_COMMESSE, data)
            })
            .catch((error) => {
                throw new Error(error)
            })
    },
    [FETCH_COMMESSA] ({
        commit
    }, commessaID) {
        return PortfolioService.get(commessaID)
            .then(({
                data
            }) => {
                commit(SET_COMMESSA, data)
                return data
            })
    },
    [FETCH_CONFIGS] ({
        commit
    }) {
        return ConfigsService.get()
            .then(({
                data
            }) => {
                commit(SET_CONFIGS, data)
            })
            .catch((error) => {
                throw new Error(error)
            })
    },
    [FETCH_CONTACTS] ({
        commit
    }) {
        return ContactsService.get()
            .then(({
                data
            }) => {
                commit(SET_CONTACTS, data)
            })
            .catch((error) => {
                throw new Error(error)
            })
    },
    [PORTFOLIO_RESET_STATE] ({
        commit
    }) {
        commit(RESET_STATE)
    }
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [FETCH_START] (state) {
        state.isLoading = true
    },
    [SET_COMMESSE] (state, data) {
        state.commesse = state.commesse.concat(data.commesse)
        state.count = data.count
        state.isLoading = false
    },
    [SET_COMMESSA] (state, commessa) {
        state.commessa = commessa
    },
    [SET_CONFIGS] (state, configs) {
        state.configs = configs
    },
    [SET_CONTACTS] (state, contacts) {
        state.contacts = contacts
    },
    [RESET_STATE] () {
        state.commesse = []
        // for (let f in state) {
        //   Vue.set(state, f, initialState[f])
        // }
    },
    [SET_CLIENT_FROM_MAP] (state, client) {
        state.currentClient = client
        if (client.isArtista) {
            state.listConfig.fartist = {
                label: client.name,
                value: client.id
            }
        }
        if (client.isDesigner) {
            state.listConfig.fdesigner = {
                label: client.name,
                value: client.id
            }
        }
        if (client.isGallery) {
            state.listConfig.fgallery = {
                label: client.name,
                value: client.id
            }
        }
        if (client.isPublisher) {
            state.listConfig.fpublisher = {
                label: client.name,
                value: client.id
            }
        }
        if (client.isMuseum) {
            state.listConfig.fmuseum = {
                label: client.name,
                value: client.id
            }
        }
        // state.listConfig.fclient = client
    },
    [RESET_CLIENT_FROM_MAP] (state) {
        state.currentClient = null
        // state.listConfig.fclient = null
    }
    // [UPDATE_ARTICLE_IN_LIST] (state, data) {
    //   state.articles = state.articles.map((article) => {
    //     if (article.slug !== data.slug) { return article }
    //     // We could just return data, but it seems dangerous to
    //     // mix the results of different api calls, so we
    //     // protect ourselves by copying the information.
    //     article.favorited = data.favorited
    //     article.favoritesCount = data.favoritesCount
    //     return article
    //   })
    // }
}

export default {
    state,
    getters,
    actions,
    mutations
}
