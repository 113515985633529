import JwtService from '@/common/jwt.service'
import {
    CalendarService
} from '@/common/api.service'
import {
    FETCH_CALENDAR
} from './actions.type'
import {
    SET_CALENDAR,
    PURGE_AUTH,
    SET_DATE
} from './mutations.type'

var today = new Date()

const state = {
    calendar: {},
    year: today.getFullYear(),
    month: ('0' + (today.getMonth() + 1)).slice(-2)
}
const getters = {
    calendar (state) {
        return state.calendar
    },
    currentMonth (state) {
        return state.month === 0 ? 1 : state.month
    },
    currentYear (state) {
        return state.year
    }
}

const actions = {
    [FETCH_CALENDAR] ({
        commit
    }, params) {
        if (JwtService.getToken()) {
            return new Promise((resolve) => {
                CalendarService.query(params.filters).then(({
                    data
                }) => {
                    commit(SET_CALENDAR, data)
                    resolve(data)
                }).catch((error) => {
                    throw new Error(error)
                })
            })
        } else {
            commit.commit(PURGE_AUTH)
        }
    }
}

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
    [SET_CALENDAR] (state, calendar) {
        state.calendar = calendar
    },
    [SET_DATE] (state, date) {
        state.month = date.month
        state.year = date.year
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
