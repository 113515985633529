<template>
    <div class="wrapper" id="wrapper-home">
        <!-- <span class="tagli" style="background-image: url(static/images/tagli.png);"></span>
    <span class="home-quote">
      <h1 v-lang.quote class="quote"></h1>
      <i class="quote sign">- Eugenio Montale</i>
    </span> -->
        <a v-lang.contact class="contact-button" @click="contactPage()"></a>
        <a class="wall-button" :href="history">Magic</a>
        <!-- <a v-lang.about target="_blank" class="about-button" :href="pdf"></a> -->
    </div>
</template>

<script>
export default {
    name: 'home-page',
    computed: {
        pdf () {
            return this.language === 'it' ? 'static/pdf/Trifolio_2018.pdf' : 'static/pdf/Trifolio_2018_en.pdf'
        },
        history () {
            return this.language === 'it' ? 'https://trifoliosrl.com/trifolio-story/it/' : 'https://trifoliosrl.com/trifolio-story/en/'
        }
    },
    methods: {
        contactPage () {
            this.$router.push('/contacts')
        },
        wallPage () {
            this.$router.push('/bellezza')
        }
    },
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Homepage | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut',
        link: [
            {
                rel: 'canonical',
                href: 'https://trifoliosrl.com'
            }
        ]
    }
}
</script>
