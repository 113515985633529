<template>
    <form novalidate class="md-layout" @submit.prevent="validateForm">
        <b-container fluid :class="{ loading: sending }">
            <div class="main-loader">
                <img src="static/images/solo_logo.svg" />
            </div>
            <b-row class="contact-wrapper mt-5 mb-5 pb-5">
                <b-col md="4">
                    <h3>Fatturazione elettronica</h3>
                    <p>La Legge di bilancio 2018 prevede l'obbligo della fatturazione elettronica per le cessioni di
                        beni e per le prestazioni di servizi rese tra privati residenti o stabiliti nel territorio dello
                        Stato, con decorrenza dal 1°gennaio 2019.<br>Al fine di poter adempiere a tale obbligo, è
                        necessario disporre di un codice destinatario rilasciato dal Sistema di Interscambio o in
                        alternativa un indirizzo attivo di Posta Elettronica Certificata (PEC) cui trasmettere le
                        fatture elettroniche. Per acquisire le suddette informazioni chiediamo cortesemente, anche alle
                        aziende già censite, di compilare il modulo.</p>
                </b-col>
                <b-col md="4">
                    <md-field :class="getValidationClass('ragionesociale')">
                        <label for="ragionesociale">Ragione Sociale</label>
                        <md-input name="ragionesociale" id="ragionesociale" autocomplete="ragionesociale"
                            v-model="form.ragionesociale" :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.ragionesociale.required">Campo richiesto</span>
                    </md-field>
                    <md-field :class="getValidationClass('partitaiva')">
                        <label for="partitaiva">Partita IVA</label>
                        <md-input name="partitaiva" id="partitaiva" autocomplete="partitaiva" v-model="form.partitaiva"
                            :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.partitaiva.required">Campo richiesto</span>
                    </md-field>
                    <md-field :class="getValidationClass('codicefiscale')">
                        <label for="codicefiscale">Codice fiscale</label>
                        <md-input name="codicefiscale" id="codicefiscale" autocomplete="codicefiscale"
                            v-model="form.codicefiscale" :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.codicefiscale.required">Campo richiesto</span>
                    </md-field>
                </b-col>
                <b-col md="4">
                    <md-field :class="getValidationClass('codicedestinatario')">
                        <label for="codicedestinatario">Codice destinatario</label>
                        <md-input name="codicedestinatario" id="codicedestinatario" autocomplete="codicedestinatario"
                            v-model="form.codicedestinatario" :disabled="sending" />
                    </md-field>
                    <md-field :class="getValidationClass('pec')">
                        <label for="pec">PEC</label>
                        <md-input type="email" name="pec" id="pec" autocomplete="pec" v-model="form.pec"
                            :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.pec.required">Campo richiesto</span>
                        <span class="md-error" v-else-if="!$v.form.pec.email">PEC invalida</span>
                    </md-field>
                    <md-field :class="getValidationClass('note')">
                        <label for="note">Note</label>
                        <md-textarea name="note" id="note" v-model="form.note" :disabled="sending" />
                    </md-field>
                    <md-progress-bar md-mode="indeterminate" v-if="sending" />
                    <md-button type="submit" class="md-raised md-primary" :disabled="sending" v-lang.send></md-button>
                    <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!
                    </md-snackbar>
                </b-col>
            </b-row>
        </b-container>
    </form>
</template>
<script>
import Vue from 'vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import axios from 'axios'
import VueSwal from 'vue-swal'
import {
    API_URL
} from '@/common/config'
import {
    validationMixin
} from 'vuelidate'
import {
    required,
    email
    // minLength,
    // maxLength
} from 'vuelidate/lib/validators'

Vue.use(VueSwal)
Vue.use(VueMaterial)
export default {
    name: 'invoice-page',
    data () {
        return {
            form: {
                ragionesociale: null,
                partitaiva: null,
                codicefiscale: null,
                codicedestinatario: null,
                pec: null,
                note: null
            },
            userSaved: false,
            sending: false,
            lastUser: null
        }
    },
    mixins: [validationMixin],
    validations: {
        form: {
            ragionesociale: {
                required
            },
            partitaiva: {
                required
            },
            codicefiscale: {
                required
            },
            pec: {
                required,
                email
            }
        }
    },
    methods: {
        getValidationClass (fieldName) {
            const field = this.$v.form[fieldName]
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        clearForm () {
            this.$v.$reset()
            this.form.ragionesociale = null
            this.form.partitaiva = null
            this.form.codicefiscale = null
            this.form.codicedestinatario = null
            this.form.note = null
            this.form.pec = null
        },
        sendEmail () {
            this.sending = true
            var data = {
                formEmail: this.form
            }
            axios.post(API_URL + '/storeData', data)
                .then(response => {
                    if (response.statusText === 'OK') {
                        this.clearForm()
                        this.sending = false
                        this.$swal('Grazie!', 'I tuoi dati sono stati registrati correttamente.', 'success')
                    }
                })
                .catch(() => {
                    this.$swal('Ooops!', 'Si è verificato un problema.', 'error')
                })
        },
        validateForm () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.sendEmail()
            }
        }
    },
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Dati Fatturazione | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut'
    }
}
</script>
<style lang="css" scoped>
.md-has-textarea label {
    left: 0 !important;
}

textarea {
    padding-left: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
</style>
