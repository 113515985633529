<template>
    <b-container fluid :class="{ loading: sending }">
        <div class="main-loader">
            <img src="static/images/solo_logo.svg" />
        </div>
        <b-row>
            <b-col>
                <gmap-map :options="options" :center="center" :zoom="7" style="width: 100%; height: 300px">
                    <gmap-marker v-for="(m, index) in markers" :key="index" :position="m.position" :clickable="true"
                        :draggable="true" :icon="m.icon" @click="center = m.position">
                    </gmap-marker>
                </gmap-map>
            </b-col>
        </b-row>
        <b-row class="contact-wrapper">
            <b-col md="4" offset-md="2">
                <h1 v-lang.contactUs></h1>
                <b-row>
                    <b-col md="6">
                        <p>VERONA, ITALY<br>Via dei Peschi, 10a<br>37141 – Verona</p>
                        <p><span v-lang.telephone></span><br>+39 045 8841020<br></p>
                    </b-col>
                    <b-col md="6">
                        <span class="team">
                            <p>
                                <a href="mailto:massimo@trifoliosrl.com">Massimo Tonolli</a><br>
                                <small v-lang.csm></small>
                            </p>
                            <p>
                                <a href="mailto:nadia@trifoliosrl.com">Nadia Bottacini</a><br>
                                <small v-lang.ao></small>
                            </p>
                        </span>
                    </b-col>
                </b-row>
                <hr style="border-color:white" />
                <b-row>
                    <b-col md="6">
                        <p>c/o, THE GRENFELL PRESS<br>NEW YORK, USA<br>116 West 29th Street<br>10001 – New York</p>
                        <p><span v-lang.telephone></span><br>+1 917 855 8657<br></p>
                    </b-col>
                    <b-col md="6">
                        <span class="team">
                            <p>
                                <a href="mailto:christina@trifoliosrl.com">Christina Grillo</a><br>
                                <small>NY production coordinator</small>
                            </p>
                        </span>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="4">
                <form id="contact-form" novalidate class="md-layout" @submit.prevent="validateForm">
                    <md-field :class="getValidationClass('company-name')">
                        <label for="company-nam" v-lang.company></label>
                        <md-input name="company-name" id="company-name" autocomplete="company-name"
                            v-model="form.companyName" :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.companyName.required">The company name is required</span>
                        <span class="md-error" v-else-if="!$v.form.companyName.minlength">Invalid company name</span>
                    </md-field>
                    <md-field :class="getValidationClass('firstName')">
                        <label for="first-name" v-lang.name></label>
                        <md-input name="first-name" id="first-name" autocomplete="given-name" v-model="form.firstName"
                            :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.firstName.required">The first name is required</span>
                        <span class="md-error" v-else-if="!$v.form.firstName.minlength">Invalid first name</span>
                    </md-field>
                    <md-field :class="getValidationClass('email')">
                        <label for="email">Email</label>
                        <md-input type="email" name="email" id="email" autocomplete="email" v-model="form.email"
                            :disabled="sending" />
                        <span class="md-error" v-if="!$v.form.email.required">The email is required</span>
                        <span class="md-error" v-else-if="!$v.form.email.email">Invalid email</span>
                    </md-field>
                    <md-field :class="getValidationClass('message')">
                        <label for="message" v-lang.message></label>
                        <md-textarea name="message" id="message" v-model="form.message" :disabled="sending" />
                    </md-field>
                    <md-field class="checkbox" :class="getValidationClass('privacy_policy')">
                        <md-checkbox name="privacy_policy" v-model="form.privacy_policy" value="1">
                        </md-checkbox>
                        <span v-lang.privacyForm></span>
                        <span class="md-error" v-if="!$v.form.privacy_policy.required">This is required</span>
                    </md-field>
                    <md-field class="checkbox" :class="getValidationClass('cookie_policy')">
                        <md-checkbox name="cookie_policy" v-model="form.cookie_policy" value="1">
                        </md-checkbox>
                        <span v-lang.marketingForm></span>
                    </md-field>
                    <md-progress-bar md-mode="indeterminate" v-if="sending" />
                    <md-button type="submit" class="md-raised md-primary" :disabled="sending" v-lang.send></md-button>
                    <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!
                    </md-snackbar>
                </form>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import axios from 'axios'
import VueSwal from 'vue-swal'
import {
    API_URL
} from '@/common/config'
import {
    validationMixin
} from 'vuelidate'
import {
    required,
    email
    // minLength,
    // maxLength
} from 'vuelidate/lib/validators'

Vue.use(VueSwal)
Vue.use(VueMaterial)
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA56h4Tjc7m7_5xuP507oTXA2gwFmFuLi8'
    }
})
export default {
    name: 'contact-page',
    data () {
        return {
            center: {
                lat: 45.4679769,
                lng: 11.0614046
            },
            markers: [{
                position: {
                    lat: 45.4679769,
                    lng: 11.0614046
                },
                icon: require('@/../static/images/trifolio-marker.png')
            }],
            options: {
                disableDefaultUI: true,
                styles: [{
                    'featureType': 'landscape.natural',
                    'elementType': 'geometry.fill',
                    'stylers': [{
                        'visibility': 'on'
                    },
                    {
                        'color': '#fffff0'
                    }
                    ]
                },
                {
                    'featureType': 'poi',
                    'elementType': 'geometry.fill',
                    'stylers': [{
                        'visibility': 'on'
                    },
                    {
                        'hue': '#1900ff'
                    },
                    {
                        'color': '#c0e8e8'
                    }
                    ]
                },
                {
                    'featureType': 'road',
                    'elementType': 'geometry',
                    'stylers': [{
                        'lightness': 100
                    },
                    {
                        'visibility': 'simplified'
                    }
                    ]
                },
                {
                    'featureType': 'road',
                    'elementType': 'labels',
                    'stylers': [{
                        'visibility': 'off'
                    }]
                },
                {
                    'featureType': 'transit.line',
                    'elementType': 'geometry',
                    'stylers': [{
                        'visibility': 'on'
                    },
                    {
                        'lightness': 700
                    }
                    ]
                },
                {
                    'featureType': 'water',
                    'elementType': 'all',
                    'stylers': [{
                        'color': '#7dcdcd'
                    }]
                }
                ]
            },
            form: {
                firstName: null,
                companyName: null,
                email: null,
                message: null,
                privacy_policy: false,
                marketing_policy: false
            },
            userSaved: false,
            sending: false,
            lastUser: null
        }
    },
    computed: {
        metaDescription () {
            return this.language === 'it' ? 'Vogliamo il massimo per le tue stampe, contattaci.' : 'Settle for nothing but the best for your art prints, contact us!'
        },
        metaTitle () {
            return this.language === 'it' ? 'Contatti' : 'Contacts'
        }
    },
    mixins: [validationMixin],
    validations: {
        form: {
            firstName: {
                required
            },
            companyName: {
            },
            privacy_policy: {
                required
            },
            marketing_policy: {
            },
            email: {
                required,
                email
            }
        }
    },
    mounted () {
        this.fetchMapConfig()
    },
    methods: {
        fetchMapConfig () {
            axios.get('https://trifoliosrl.com/static/json/customMap.json').then(response => {
                this.options.styles = response.data
            })
        },
        getValidationClass (fieldName) {
            const field = this.$v.form[fieldName]
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        clearForm () {
            this.$v.$reset()
            this.form.firstName = null
            this.form.companyName = null
            this.form.email = null
            this.form.message = null
            this.form.privacy_policy = false
            this.form.marketing_policy = false
        },
        sendEmail () {
            this.sending = true
            var data = {
                formEmail: this.form
            }
            axios.post(API_URL + '/sendEmail', data)
                .then(
                    response => {
                        if (response.status === 200 && response.statusText === 'OK') {
                            this.clearForm()
                            this.sending = false
                            this.$swal('Thank you!', 'Message has beeb sent.', 'success')
                            window._iub.cons_instructions.push(
                                [
                                    'submit',
                                    {
                                        form: {
                                            selector: 'contact-form',
                                            map: {
                                                subject: {
                                                    email: 'email',
                                                    first_name: 'first-name'
                                                },
                                                preferences: {
                                                    generic: 'privacy_policy',
                                                    newsletter: 'marketing_policy'
                                                }
                                            }
                                        },
                                        consent: {
                                            legal_notices: [{
                                                identifier: 'privacy_policy'
                                            },
                                            {
                                                identifier: 'cookie_policy'
                                            },
                                            {
                                                identifier: 'terms'
                                            }]
                                        }
                                    }, {
                                        success: function (response) {
                                            console.log(response)
                                        },
                                        error: function (response) {
                                            console.log(response)
                                        }
                                    }
                                ]
                            )
                        } else {
                            this.$swal('Ooops!', 'There was an error.', 'error')
                        }
                    })
            // .catch(e => {
            //     this.$swal('Ooops!', 'There was an error.', 'error')
            // })
            // Instead of this timeout, here you can call your API
            // window.setTimeout(() => {
            //   this.lastUser = `${this.form.firstName} ${this.form.lastName}`
            //   this.userSaved = true
            //   this.sending = false
            //   this.clearForm()
            // }, 1500)
        },
        validateForm () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.sendEmail()
            }
        }
    },
    metaInfo () {
        return {
            title: this.metaTitle,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://trifoliosrl.com/contact'
                }
            ],
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                }
            ]
        }
    }
}
</script>
<style lang="css">
.md-checkbox-label {
    position: static !important;
    pointer-events: all !important;
}

.md-field.checkbox a {
    text-decoration: underline !important;
}

.md-checkbox.md-theme-default {
    margin: 4px 15px 0 0;
}

.md-checkbox.md-theme-default .md-checkbox-container {
    border-color: rgba(255, 255, 230, .4);
}

.md-field.checkbox:before,
.md-field.checkbox:after {
    display: none;
}
</style>
