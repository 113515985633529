<template>
    <div id="myCal">
        <div class="myCal-years">
            <div class="row">
                <div class="col text-center">
                    <a href="#" class="month-nav" role="button" @click="downYear()"><i class="fa fa-angle-left" /></a>
                </div>
                <div class="col text-center">
                    <span class="nav-year">{{ currentYear }}</span>
                </div>
                <div class="col text-center">
                    <a href="#" class="month-nav" role="button" @click="upYear()"><i class="fa fa-angle-right" /></a>
                </div>
            </div>
        </div>
        <div class="myCal-months">
            <div class="row no-gutters">
                <div v-for="(month, key) in months" :key="key" :class="{active: parseInt(currentMonth) === month.value}"
                    class="col-lg-4 col-6 single-month">
                    <a href="javascript:;" role="button" @click="setMonth(key)">
                        <span v-if="language === 'it'">{{ month.names.it }}</span>
                        <span v-else>{{ month.names.en }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import {
    SET_DATE
} from '@/store/mutations.type'
export default {
    data () {
        return {
            months: [{
                value: 1,
                names: {
                    it: 'Gennaio',
                    en: 'January'
                }
            },
            {
                value: 2,
                names: {
                    it: 'Febbraio',
                    en: 'February'
                }
            },
            {
                value: 3,
                names: {
                    it: 'Marzo',
                    en: 'March'
                }
            },
            {
                value: 4,
                names: {
                    it: 'Aprile',
                    en: 'April'
                }
            },
            {
                value: 5,
                names: {
                    it: 'Maggio',
                    en: 'May'
                }
            },
            {
                value: 6,
                names: {
                    it: 'Giugno',
                    en: 'June'
                }
            },
            {
                value: 7,
                names: {
                    it: 'Luglio',
                    en: 'July'
                }
            },
            {
                value: 8,
                names: {
                    it: 'Agosto',
                    en: 'August'
                }
            },
            {
                value: 9,
                names: {
                    it: 'Settembre',
                    en: 'September'
                }
            },
            {
                value: 10,
                names: {
                    it: 'Ottobre',
                    en: 'October'
                }
            },
            {
                value: 11,
                names: {
                    it: 'Novembre',
                    en: 'November'
                }
            },
            {
                value: 12,
                names: {
                    it: 'Dicembre',
                    en: 'December'
                }
            }
            ]
        }
    },
    computed: {
        cmonth: {
            get: function () {
                return this.currentMonth
            },
            set: function (newValue) {
                this.$store.commit(SET_DATE, {
                    month: newValue,
                    year: this.cyear
                })
                this.$emit('changeDate')
            }
        },
        cyear: {
            get: function () {
                return this.currentYear
            },
            set: function (newValue) {
                this.$store.commit(SET_DATE, {
                    month: this.cmonth,
                    year: newValue
                })
                this.$emit('changeDate')
            }
        },
        ...mapGetters([
            'currentMonth',
            'currentYear'
        ])
    },
    methods: {
        downYear: function () {
            this.cyear--
        },
        upYear: function () {
            this.cyear++
        },
        setMonth: function (key) {
            this.cmonth = ('0' + (key + 1)).slice(-2)
        }
    }
}
</script>
<style>
#myCal {
    border-bottom: 2px solid #555555;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
}

.nav-year {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
}

.month-nav {
    color: #555555;
    font-size: 24px;
    line-height: 1;
}

.myCal-years {
    margin: 20px 0;
}

.myCal-months .single-month a {
    display: block;
    text-decoration: none;
}

.myCal-months .single-month a:hover span {
    background: #d0d0d0;
}

.myCal-months .single-month a span {
    font-weight: 700;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: #555555;
    text-align: center;
    padding: 12px 10px;
}

.myCal-months .single-month.active a span {
    background: #555555;
    color: #ffffff;
}
</style>
