<template>
    <footer id="colophon" class="site-footer container-fluid" role="contentinfo">
        <div class="row">
            <div class="col">
                <div class="site-info">
                    <router-link v-if="language === 'it'" to="trattamento">Trattamento Dati.</router-link>
                    <router-link to="privacy">Policy</router-link>.
                    <router-link v-if="language === 'it'" to="invoice">Fatturazione Elettronica.</router-link>
                    <a href="http://www.deepartweb.com">Credits</a>
                    <br>© 2018 • Trifolio S.r.l. P.Iva 03015390234.
                    <span v-if="language === 'it'">Fotografie di Alberto Cafaro</span>
                    <span v-else>Photographs by Alberto Cafaro</span>
                    <span class="privacy-info"> | <a href="https://www.iubenda.com/privacy-policy/38480994" target="_blank"
                            class="" title="Privacy Policy">Privacy Policy</a> | <a
                            href="https://www.iubenda.com/privacy-policy/38480994/cookie-policy" target="_blank" class=""
                            title="Cookie Policy">Cookie Policy</a></span>
                </div>
            </div>
            <div v-if="$route.path === '/'" class="col">
                <ul class="footer-menu">
                    <li class="nav-item vimeo">
                        <a href="https://vimeo.com/user80945579" target="_blank">
                            <img src="static/images/vimeo.svg" alt="Vimeo" />
                        </a>
                    </li>
                    <li class="nav-item facebook">
                        <a href="https://www.facebook.com/trifoliosrl" target="_blank">
                            <img src="static/images/facebook.svg" alt="Facebook" />
                        </a>
                    </li>
                    <li class="nav-item instagram">
                        <a href="https://www.instagram.com/trifoliosrl" target="_blank">
                            <img src="static/images/instagram.svg" alt="Instagram" />
                        </a>
                    </li>
                    <li class="nav-item newsletter">
                        <a href="https://my.sendinblue.com/users/subscribe/js_id/3k502/id/1" target="_blank">
                            <img src="static/images/newsletter.svg" alt="Newsletter" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'main-footer'
}
</script>
