import Vue from 'vue'
import Router from 'vue-router'
// import HelloWorld from '@/components/HelloWorld'
import Homepage from '@/views/homepage'
import Portfolio from '@/views/portfolio'
import Single from '@/views/single'
import Areaw4 from '@/views/areaw4'
import AreaRiservata from '@/views/areariservata'
import Calendar from '@/views/calendar'
import Contact from '@/views/contact'
import Invoice from '@/views/invoice'
import World from '@/views/WorldPage'
import Wall from '@/views/wall'
import Privacy from '@/views/privacy'
import Trattamento from '@/views/trattamento'
import Login from '@/views/login'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    linkActiveClass: 'open active',
    // scrollBehavior: () => ({ y: 0 }),
    scrollBehavior: (to, from, savedPosition) => new Promise((resolve) => {
        const position = savedPosition || {}
        if (!savedPosition) {
            if (to.hash) {
                position.selector = to.hash
            }
            if (to.matched.some((m) => m.meta.scrollToTop)) {
                position.x = 0
                position.y = 0
            }
        }
        router.app.$root.$once('triggerScroll', () => {
            router.app.$nextTick(() => resolve(position))
        })
    }),
    routes: [
        {
            path: '/',
            name: 'Homepage',
            component: Homepage
        },
        {
            path: '/contacts',
            name: 'Contacts',
            component: Contact
        },
        {
            path: '/invoice',
            name: 'Invoice',
            component: Invoice
        },
        {
            path: '/world',
            name: 'World',
            component: World
        },
        {
            path: '/bellezza',
            name: 'Bellezza',
            component: Wall
        },
        {
            path: '/portfolio',
            component: Portfolio,
            name: 'Portfolio',
            children: [
                {
                    path: ':id',
                    name: 'Single',
                    component: Single,
                    props: true,
                    meta: {
                        showModal: true
                    }
                }
            ]
        },
        // {
        //   path: '/portfolio/:id',
        //   component: Single,
        //   name: 'Single',
        //   props: true
        // },
        {
            path: '/area-w4',
            name: 'Area W4',
            component: Areaw4
        },
        {
            path: '/area-riservata',
            name: 'Reserved Area',
            component: AreaRiservata
        },
        {
            path: '/calendar',
            name: 'Calendar',
            component: Calendar
        },
        {
            name: 'login',
            path: '/login',
            component: Login
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: Privacy
        },
        {
            path: '/trattamento',
            name: 'Trattamento',
            component: Trattamento
        }
    ]
})

export default router
