export default {
    items: [{
        name: {
            it: 'Portfolio',
            en: 'Portfolio'
        },
        url: '/portfolio',
        reserved: false
    },
    {
        name: {
            it: 'Mondo',
            en: 'World'
        },
        url: '/world',
        reserved: false
    },
    {
        name: {
            it: 'Area W4',
            en: 'Area W4'
        },
        url: '/area-w4',
        reserved: false
    },
        // {
        //     name: {it: 'Bellezza', en: 'Bellezza'},
        //     url: '/bellezza',
        //     reserved: false
        // },
    {
        name: {
            it: 'Contatti',
            en: 'Contacts'
        },
        url: '/contacts',
        reserved: false
    },
    {
        name: {
            it: 'Area Riservata',
            en: 'Reserved Area'
        },
        url: '/area-riservata',
        reserved: false
    }
    ]
}
