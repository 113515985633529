import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'
import { API_URL } from '@/common/config'
import { PURGE_AUTH } from '@/store/mutations.type'

const ApiService = {
    init () {
        Vue.use(VueAxios, axios)
        Vue.axios.defaults.baseURL = API_URL
        Vue.axios.interceptors.response.use(undefined, function (err) {
            return new Promise(function () {
                if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                    this.$store.dispatch(PURGE_AUTH)
                }
                throw err
            })
        })
    },

    setHeader () {
        Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`
    },

    query (resource, params) {
        return Vue.axios
            .get(resource, params)
            .catch((error) => {
                throw new Error(`[RWV] ApiService ${error}`)
            })
    },

    queryCount (resource, params) {
        return Vue.axios.get(resource, params).then((response) => {
            return { data: { commesse: response.data, count: response.headers['x-total-count'] } }
        }).catch((error) => {
            throw new Error(`[RWV] ApiService ${error}`)
        })
    },

    get (resource, param = '') {
        return Vue.axios
            .get(`${resource}/${param}`)
            .catch((error) => {
                throw new Error(`[RWV] ApiService ${error}`)
            })
    },
    post (resource, params) {
        return Vue.axios.post(`${resource}`, params)
    }
}

export default ApiService

export const PortfolioService = {
    query (params) {
        return ApiService
            .queryCount(
                'portfolio/commesse',
                { params: params }
            )
    },
    get (id) {
        return ApiService
            .query('portfolio/commesse/' + id,
                { params: { format: 'ext' } }
            )
    }
}

export const ConfigsService = {
    query () {
        return ApiService
            .query(
                'config'
            )
    },
    get () {
        return ApiService.get('config')
    }
}

export const ContactsService = {
    query () {
        return ApiService
            .query(
                'contacts'
            )
    },
    get () {
        return ApiService.get('contacts')
    }
}

export const CalendarService = {
    query (params = '') {
        return ApiService
            .query(
                'calendar/events',
                { params: params }
            )
    }
}
