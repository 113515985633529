// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Meta from 'vue-meta'
import router from './router'
import store from '@/store'
import { CHECK_AUTH } from '@/store/actions.type'
import MultiLanguage from 'vue-multilanguage'
import ApiService from '@/common/api.service'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import language from './lang/language'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/Rome')

Vue.use(VueMoment)
Vue.use(Meta)
Vue.use(BootstrapVue)
Vue.use(MultiLanguage, language)

Vue.config.productionTip = false
ApiService.init()
// Ensure we checked auth before each page load.
router.beforeEach(
    (to, from, next) => {
        if (to.path === '/area-riservata' || to.path === '/calendar') {
            return Promise.all([store.dispatch(CHECK_AUTH)]).then(function () {
                if (!store.state.auth.isAuthenticated) {
                    next({
                        path: '/login'
                    })
                } else {
                    next()
                }
            })
        } else {
            next()
        }
    }
)


/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
})