export const SET_AUTH = 'setUser'
export const RE_SET_AUTH = 'resetUser'
export const PURGE_AUTH = 'logOut'
export const SET_ERROR = 'setError'
export const FETCH_START = 'setLoading'
export const SET_COMMESSE = 'setCommesse'
export const SET_TOTAL_COMMESSE = 'setTotalCommesse'
export const SET_CONFIGS = 'setConfigs'
export const SET_CONTACTS = 'setContacts'
export const SET_COMMESSA = 'setCommessa'
export const SET_CALENDAR = 'setCalendar'
export const RESET_STATE = 'resetState'
export const SET_DATE = 'setDate'
export const SET_CLIENT_FROM_MAP = 'setClient'
export const RESET_CLIENT_FROM_MAP = 'resetClient'
