<template>
    <div class="auth-page">
        <div class="login-wrapper">
            <span class="close hairline"></span>
            <span v-if="errors" class="error-messages text-center">
                {{ errors.data }}
            </span>
            <h3 v-lang.access></h3>
            <form class="md-layout" v-on:submit.prevent="onSubmit(username, password)">
                <md-field>
                    <label>Username</label>
                    <md-input v-model="username"></md-input>
                </md-field>
                <md-field :md-toggle-password="false">
                    <label>Password</label>
                    <md-input v-model="password" type="password"></md-input>
                </md-field>
                <button class="">Login</button>
            </form>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import {
    mapState
} from 'vuex'
import {
    LOGIN
} from '@/store/actions.type'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)

export default {
    name: 'Login-Page',
    data () {
        return {
            username: null,
            password: null
        }
    },
    methods: {
        onSubmit (username, password) {
            this.$store
                .dispatch(LOGIN, {
                    username,
                    password
                })
                .then(() => this.$router.push({
                    name: 'Reserved Area'
                }))
        }
    },
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        })
    },
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Login | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut'
    }
}
</script>

<style>
.auth-page .close {
    float: none;
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    padding: 30px;
    background-color: #222;
    border-radius: 100%;
    opacity: 1;
    margin: 0 auto 40px;
}

.auth-page .close:hover::before,
.auth-page .close:hover::after {
    background: #1ebcc5;
}

.auth-page .close::before,
.auth-page .close::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 50%;
    top: 50%;
    left: 20px;
    margin-top: -1px;
    background: #fff;
}

.auth-page .close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.auth-page .close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.auth-page {
    position: absolute;
    width: 100%;
    height: calc(100% - 116px);
}

.auth-page h3 {
    text-align: center;
}

.auth-page button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 40px;
    margin: 40px auto 0;
    display: block;
    font-size: 12px;
}

.login-wrapper {
    width: 350px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

/*input:-webkit-autofill,
form fieldset input{
  -webkit-box-shadow: 0 0 0px 1000px #fefeef inset;
  background-color: rgb(255,255,255) !important;
  border: none;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}*/
</style>
