<template>
    <div id="area-riservata" :class="{ loading: isLoading }">
        <div class="main-loader">
            <img src="static/images/solo_logo.svg" />
        </div>
        <b-container fluid>
            <b-row>
                <b-col md="4">
                    <div class="client-details">
                        <span class="big-letter">
                            <h1 class="first-letter">{{ firstLetter }}</h1>
                        </span>
                        <h2>{{ currentUser.name }}</h2>
                    </div>
                    <div class="text-center calendar-link">
                        <router-link :to="{ name: 'Calendar' }">
                            <i class="fa fa-calendar fa-3x"></i>
                            <br><span v-lang.calendar></span>
                        </router-link>
                        <div class="mt-5"></div>
                    </div>
                    <div v-if="computedCalendar.length > 0" class="works-list text-center">
                        <h4 v-lang.nextworks></h4>
                        <ul>
                            <li v-for="(event, index) in computedCalendar" :key="index">
                                <a href="javascript:;" v-b-popover.hover="event.descr" :title="event.title">
                                    <b>{{ event.title }}</b> <br><small>{{ event.from | moment("MM/DD/YYYY") }} ➟
                                        {{ event.to | moment("MM/DD/YYYY") }}</small>
                                </a>
                            </li>
                        </ul>
                    </div>
                </b-col>
                <b-col class="user-works-list">
                    <b-row>
                        <b-col md="6" v-for="(commessa, index) in commesse" :key="index" class="single-work-list">
                            <singleWork :commessa="commessa"></singleWork>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import {
    mapGetters
} from 'vuex'
import singleWork from '@/components/singleWork'
import {
    FETCH_COMMESSE,
    FETCH_CALENDAR,
    PORTFOLIO_RESET_STATE
} from '@/store/actions.type'

export default {
    name: 'area-riservata',
    components: {
        singleWork
    },
    data () {
        return {
            firstLetter: 'A'
        }
    },
    watch: {
        currentUser: function () {
            this.firstLetter = this.currentUser.name.substring(0, 1)
        }
    },
    computed: {
        filters () {
            return {
                page: 1,
                limit: 50,
                fpublic: true,
                fmuseum: parseInt(this.currentUserType) === 386 ? '' : this.currentUserId,
                fgallery: parseInt(this.currentUserType) === 386 ? '' : this.currentUserId,
                fpublisher: parseInt(this.currentUserType) === 386 ? '' : this.currentUserId,
                fdesigner: parseInt(this.currentUserType) === 386 ? '' : this.currentUserId,
                fartist: parseInt(this.currentUserType) === 386 ? '' : this.currentUserId,
                fmanager: parseInt(this.currentUserType) === 386 ? '' : this.currentUserId,
                mode: parseInt(this.currentUserType) === 386 ? '' : 'or'
                // fclient: this.currentUserId === '612' ? '' : this.currentUserId
            }
        },
        listConfig () {
            const filters = {
                fclient: this.currentUserId,
                orderBy: 'from'
            }
            return {
                filters
            }
        },
        ...mapGetters([
            'calendar',
            'currentUserId',
            'currentUserType',
            'currentUser',
            'isAuthenticated',
            'isLoading',
            'commesse'
        ]),
        computedCalendar () {
            if (Array.isArray(this.calendar)) {
                return this.calendar.filter((c) => {
                    return this.$moment(c.to) > this.$moment()
                })
            } else {
                return []
            }
        }
    },
    mounted () {
        this.fetchCommesse()
        this.fetchCalendar()
    },
    methods: {
        fetchCalendar () {
            this.$store.dispatch(FETCH_CALENDAR, this.listConfig)
        },
        fetchCommesse () {
            this.$store.dispatch(PORTFOLIO_RESET_STATE)
            this.$store.dispatch(FETCH_COMMESSE, this.filters)
        }
    },
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Reserved Area | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut',
        link: [
            {
                rel: 'canonical',
                href: 'https://trifoliosrl.com/login'
            }
        ]
    }
}
</script>
