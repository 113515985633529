<template>
    <div id="area-w4">
        <b-container fluid>
            <b-row>
                <b-col md="4">
                    <div class="fixedsticky">
                        <div class="area-w4-title text-center">
                            <!-- <p>PRINTING PROCESS</p> -->
                            <img class="aw4-logo" src="static/images/AREAW4_ok.svg" alt="">
                            <!-- <h1>Area W4</h1> -->
                        </div>
                        <div class="area-w4-intro">
                            <p v-if="language === 'it'">AreaW4 è un processo di stampa offset a elevate prestazioni, che
                                riproduce fino a 520.000 toni di colore in quadricromia (+40% rispetto ai limiti fissati
                                dagli standard ISO).<br>
                                Ideato da Trifolio per realizzare libri di qualità superiore, è un progetto aperto, in
                                continua e rapida evoluzione, che unisce sapientemente le intuizioni degli sviluppatori
                                con i progressi della tecnologia.<br>
                                AreaW4 è un laboratorio di idee innovative, un reparto di ricerca avanzato e una realtà
                                produttiva concreta. Trifolio garantisce ai propri clienti una qualità di stampa
                                visibilmente non consueta nel panorama dell’editoria d’arte.</p>
                            <p v-else>AreaW4 is a high-performance offset printing process that can reproduce up to
                                520,000 tones in four-color printing (40 percent more than the requirements of the ISO
                                standard). Conceived by Trifolio to produce books of superior quality, it is an “open”
                                process, continually and rapidly evolving, that skillfully unites the intuition and
                                knowledge of its developers with advances in technology. AreaW4 is a workshop of
                                innovative ideas, an advanced research department, and a results-oriented production
                                facility. Trifolio guarantees its clients a print quality that stands out within the
                                panorama of art publishing.</p>
                        </div>
                        <div class="area-w4-nav text-center">
                            <h3><span v-if="language === 'it'">Argomenti</span><span v-else>Topics</span></h3>
                            <ul>
                                <li>
                                    <a href="#" v-scroll-to="'#w4-home'">
                                        <span v-if="language === 'it'">Inizio</span><span v-else>Top</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" v-scroll-to="'#chapter-0'">
                                        <span v-if="language === 'it'">Test di stampa</span><span v-else>Print test</span>
                                        <b-badge pill variant="info">NEW !!</b-badge>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" v-scroll-to="'#chapter-1'">
                                        <span v-if="language === 'it'">Uomo e colore</span><span v-else>Colour and the human
                                            eye</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" v-scroll-to="'#chapter-2'">Wide CMYK Performance</a>
                                </li>
                                <li>
                                    <a href="#" v-scroll-to="'#chapter-3'">
                                        <span v-if="language === 'it'">Perchè?</span><span v-else>Why?</span>
                                    </a>
                                </li>
                                <!-- <li><a href="#" v-scroll-to="'#chapter-4'">Print Test</a></li> -->
                            </ul>
                        </div>
                    </div>
                </b-col>
                <b-col>
                    <div class="w4-content-wrapper">
                        <div class="w4-content">
                            <section id="w4-home" style="padding-top:130px">
                                <div style="padding:56.25% 0 0 0;position:relative;">
                                    <iframe v-if="language === 'it'"
                                        src="https://player.vimeo.com/video/493249224?h=f1398a38a1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                                        style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="AreaW4 | Trifolio printing process (Versione in Italiano)"></iframe>
                                    <iframe v-else
                                        src="https://player.vimeo.com/video/493245528?h=04d3958197&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                                        style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                        title="AreaW4 | Trifolio printing process (English Version)"></iframe>
                                </div>
                                <!-- <img class="fluid" src="static/images/Pigmenti_4.jpg" alt="" /> -->
                            </section>
                            <section id="chapter-0">
                                <b-row>
                                    <b-col>
                                        <h2 v-if="language === 'it'">Test di stampa</h2>
                                        <h2 v-else>Print test</h2>
                                        <p v-if="language === 'it'">Le quattro immagini rappresentano le conversioni da
                                            files RGB.<br>
                                            La prima è conforme allo standard ISO 12647-2 la seconda versione è la
                                            conversione "AreaW4 printing process". <br>
                                            <strong class="highlighted">Passa con il mouse sopra le immagini,
                                                apprezzerai la differenza</strong><br>
                                            <small>Le quantità e la qualità delle differenze che potete apprezzare
                                                dipenderanno dal monitor che utilizzate e dalla taratura dello
                                                stesso.</small><br>
                                        </p>
                                        <p v-else>The following four figures represent files which have been converted
                                            from the RGB files.<br>
                                            The first one converted using the standard ISO 12647-2 process, the second
                                            one converted using the AreaW4 process.<br>
                                            <strong class="highlighted">Mouse hover, you can easy appreciate the
                                                difference.</strong>
                                        </p>
                                        <div class="w4-hovers">
                                            <span>
                                                <img class="fluid" src="static/images/Blu_ISO.jpg" alt="" />
                                                <img class="fluid aw4" src="static/images/Blu_AW4.jpg" alt="" />
                                            </span>
                                            <span>
                                                <img class="fluid" src="static/images/Gialli_ISO.jpg" alt="" />
                                                <img class="fluid aw4" src="static/images/Gialli_AW4.jpg" alt="" />
                                            </span>
                                            <span>
                                                <img class="fluid" src="static/images/Rossi_ISO.jpg" alt="" />
                                                <img class="fluid aw4" src="static/images/Rossi_AW4.jpg" alt="" />
                                            </span>
                                            <span>
                                                <img class="fluid" src="static/images/Verdi_ISO.jpg" alt="" />
                                                <img class="fluid aw4" src="static/images/Verdi_AW4.jpg" alt="" />
                                            </span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </section>
                            <section id="chapter-1">
                                <b-row>
                                    <b-col>
                                        <div v-if="language === 'it'">
                                            <h2>Uomo e colore</h2>
                                            <p>La teoria del colore è un ramo specifico della fisica ottica, che studia
                                                la classificazione dei colori e la loro percezione da parte dell’occhio
                                                umano. Il colore è un fenomeno fisico che l’uomo percepisce grazie a tre
                                                condizioni fondamentali:</p>
                                            <h3>La Luce</h3>
                                            <p>La luce è una radiazione elettromagnetica emessa da una sorgente luminosa
                                                di lunghezza d’onda compresa tra 380 e 780 nm e occupa la porzione di
                                                spettro elettromagnetico tra l’infrarosso e l’ultravioletto. La luce che
                                                noi percepiamo bianca è in realtà composta da radiazioni di lunghezza
                                                d’onda diversa comprese in questo intervallo del visibile, dal violetto
                                                al rosso. Prova ne è che quando la luce attraversa un prisma trasparente
                                                o le goccioline in sospensione nell’atmosfera si scompone
                                                nell’arcobaleno.<br>
                                                Per la comprensione dei nostri sistemi di stampa, le caratteristiche
                                                della luce che interessano maggiormente sono:<br>
                                                <img class="fluid aw4" src="static/images/temperatura_luce.svg" alt="" />
                                                <strong>Temperatura di colore</strong><br>
                                                La temperatura di colore di una sorgente luminosa è una misura numerica
                                                della sua apparenza cromatica (tonalità). Si basa sul principio che
                                                qualunque oggetto, se viene riscaldato a una temperatura
                                                sufficientemente elevata, emette luce. Il colore di quella luce varia
                                                con un andamento prevedibile all’aumentare della temperatura: l’oggetto
                                                passa gradualmente dal rosso all’arancio, poi al giallo e al bianco,
                                                fino al bianco-azzurro. Pertanto la temperatura di colore è la
                                                temperatura, espressa in gradi kelvin (K), alla quale il colore
                                                dell’oggetto corrisponde esattamente a quello della sorgente
                                                luminosa.<br>
                                                <strong>Indice di resa cromatica</strong><br>
                                                L’indice di resa cromatica (Ra) fornisce indicazioni su come una
                                                determinata sorgente luminosa rende i colori degli oggetti illuminati. È
                                                un sistema derivato dagli esperimenti sulla visione per valutare
                                                l’impatto esercitato da differenti sorgenti luminose sul colore
                                                percepito di oggetti e superfici.<br><br>
                                                <strong>Proprietà fisiche della materia</strong>
                                                Le caratteristiche della materia sono una variabile determinante
                                                rispetto al colore percepito. Infatti gli oggetti sottoposti alla
                                                radiazione luminosa reagiscono diversamente a seconda della propria
                                                sostanza: un oggetto che assorbe tutte le lunghezze d’onda della luce
                                                risulta nero; al contrario, se non ne assorbe nessuna risulta bianco; se
                                                infine è selettivo ci appare nei milioni di colori che la natura ci
                                                mostra.<br><br>
                                                <img class="fluid aw4" src="static/images/spettro.jpg" alt="" /><br><br>
                                                <strong>Fisiologia dell’occhio</strong><br>
                                                L’occhio è l’organo di senso principale dell’apparato visivo e ha il
                                                compito di ricevere le informazioni dall’ambiente esterno attraverso la
                                                luce. È un sistema ottico in cui il cristallino funge da obiettivo e la
                                                retina da rivelatore della luce mediante uno strato di ricettori (coni e
                                                bastoncelli) collegati al cervello attraverso il nervo ottico. I
                                                bastoncelli sono deputati alla visione in condizioni di bassa
                                                illuminazione, ma non sono particolarmente sensibili al colore; invece i
                                                coni sono di tre tipi sensibili, in corrispondenza di diverse lunghezze
                                                d’onda (rosso, verde e blu) e determinano con una certa precisione la
                                                percezione del tono.<br>
                                                La visione di un colore nonché la capacità di distinguere una variazione
                                                di tono restano comunque un fatto soggettivo, dipendente dalla
                                                concentrazione, dall’allenamento, dalle condizioni ambientali
                                                eccetera.<br><br>
                                                <img class="fluid aw4" src="static/images/retina.jpg" alt="" /><br><br>
                                                A questo punto è possibile affermare che un colore si può determinare in
                                                due modi: variando la luce (sintesi additiva) o variando le
                                                caratteristiche della materia (sintesi sottrattiva).<br>
                                                Avendo a disposizione tre sorgenti di luce di lunghezza d’onda singola e
                                                specifica, corrispondenti a luce rossa, blu e verde, si possono sommare
                                                in combinazioni e quantità (energia) diverse in modo da ottenere diversi
                                                toni di colore, visibili all’occhio, illuminando con questo fascio una
                                                materia bianca. È il principio con cui funzionano video e fotocamere
                                                digitali. È chiamata sintesi additiva.<br>
                                                Utilizzando invece una luce bianca, ovvero una luce comprendente tutto
                                                lo spettro del visibile e colorando la materia bianca con tre colori
                                                (azzurro, giallo e rosso cremisi) in quantità diverse, il risultato è
                                                diversi toni di colore. È il principio con cui funzionano la stampa, la
                                                pittura, le pellicole fotografiche e cinematografiche. È chiamata
                                                sintesi sottrattiva.<br>
                                                Valutando in 7,5 milioni i colori esistenti, con un calcolo empirico si
                                                possono fare queste considerazioni:<br><br>
                                                l’uomo è in grado di distinguerne circa un terzo (2,5 milioni)<br>
                                                le tecnologie con sintesi additiva ne riproducono circa un quarto (1,8
                                                milioni)<br>
                                                le tecnologie con sintesi sottrattiva ne riproduce al massimo 1,3
                                                milioni<br>
                                                il sistema standard offset non supera i 400 mila colori<br><br>
                                                <img class="fluid aw4" src="static/images/sintesi.jpg" alt="" /><br><br>
                                                È evidente che la tecnica fotografica, sebbene sia lontana dal poter
                                                riprodurre tutte le variazioni tonali esistenti, non è distante da
                                                quello che è in grado di percepire l’occhio umano. Al contrario, una
                                                pubblicazione cartacea standard ha dei limiti notevoli, perché non
                                                riesce a riprodurre né un terzo dei colori che potrebbero essere
                                                presenti in un affresco o in un dipinto né un quinto di quelli possibili
                                                in una fotografia.
                                            </p>
                                        </div>
                                        <div v-else>
                                            <h2>Colour and the human eye</h2>
                                            <p>Color theory is a branch of optics that studies the classification of
                                                colors and their perception by the human eye. Color is a physical
                                                phenomenon that we perceive thanks to three fundamental conditions:</p>
                                            <h3>Light</h3>
                                            <p>Light is electromagnetic radiation emitted by a light source with a
                                                wavelength of between 380 and 780 nanometers; it occupies the portion of
                                                the electromagnetic spectrum between the infrared and the ultraviolet.
                                                The light that we perceive as white is in reality a blend of different
                                                wavelengths in this interval of visible light, from violet to red. Proof
                                                of this is provided by the fact that when light passes through a
                                                transparent prism, it is refracted into a spectrum of color; we also see
                                                this when light passes through drops of water suspended in the
                                                atmosphere, where it is refracted into a rainbow.<br>
                                                In order to understand the printing process, there are two important
                                                characteristics of light to bear in mind:<br>
                                                <img class="fluid aw4" src="static/images/temperatura_luce.svg" alt="" />
                                                <strong>Colour temperature</strong><br>
                                                The color temperature of a light source is the numerical measure of its
                                                chromatic appearance, or hue. It is based on the principle that any
                                                object, if heated to a sufficiently high temperature, gives off light.
                                                The color of that light varies in a predictable manner with the increase
                                                in temperature: the object passes gradually from red to orange to yellow
                                                and then white and finally to bluish white. Thus the color temperature
                                                is the temperature, expressed in degrees kelvin (K), at which the color
                                                of the object exactly matches that of the light source.<br>
                                                <strong>Colour rendering index</strong><br>
                                                The color rendering index (CRI or Ra) provides indications about how a
                                                particular light source renders the colors of the objects illuminated.
                                                It is a system derived from experiments carried out on vision to assess
                                                the impact of different light sources on the perceived color of objects
                                                and surfaces.<br>
                                                <strong>The physical properties of the material</strong><br>
                                                The characteristics of the material are a decisive factor in how color
                                                is perceived. In fact, objects illuminated by light react in different
                                                ways according to their substance: an object that absorbs all the
                                                wavelengths of visible light appears black; if, on the contrary, it
                                                absorbs none of them, it appears white; if it is selective in the
                                                wavelengths it absorbs, it appears in one of the millions of colors that
                                                we find in nature.<br><br>
                                                <img class="fluid aw4" src="static/images/spettro.jpg" alt="" /><br><br>
                                                <strong>The physiology of the eye</strong><br>
                                                The eye, the principal sense organ of our visual system, receives
                                                information from the external environment through light. The crystalline
                                                lens focuses the light and the retina detects it by means of a layer of
                                                receptors, called cones and rods, which is connected to the brain by the
                                                optic nerve. The rods are used for vision in low-light conditions but
                                                are not particularly sensitive to color; cones, on the other hand, come
                                                in three types, which are each sensitive to different wavelengths (red,
                                                green, and blue), and determine with a certain precision the perception
                                                of the hue.<br>
                                                Despite this ability to distinguish variation in tone, the perception of
                                                color remains a subjective matter, dependent on the viewer’s
                                                concentration, habits, environmental conditions, and more.<br><br>
                                                <img class="fluid aw4" src="static/images/retina.jpg" alt="" /><br><br>
                                                A color can be determined in two ways: by varying the light (additive
                                                synthesis) or by varying the characteristics of the material
                                                (subtractive synthesis).<br>
                                                Three light sources, each of a single and specific wavelength that
                                                correspond to red, blue, or green light, can be combined in different
                                                combinations and quantities (i.e., intensity) in such a way as to obtain
                                                different shades of color, visible to the eye when a white material is
                                                illuminated with this beam. It is the principle on which digital video
                                                and still cameras operate, called additive synthesis.<br>
                                                If instead we use white light (i.e., light covering the whole spectrum
                                                of visible wavelengths), and tint the white material with three colors
                                                (blue, yellow, and red) in different quantities, the result is also a
                                                range of colors, but it has been created by partially absorbing, or
                                                subtracting, the white light. This is the principle on which printing,
                                                painting, and photographic film and film stock are based, called
                                                subtractive synthesis.<br>
                                                If we estimate the number of colors in existence to be 7.5 million, an
                                                empirical calculation leads to the following considerations:<br>
                                                humans are capable of distinguishing around a third of this number (2.5
                                                million)<br>
                                                technologies based on additive synthesis reproduce around a quarter (1.8
                                                million)<br>
                                                technologies based on subtractive synthesis reproduce 1.3 million at the
                                                most<br>
                                                the standard offset system does not exceed 400 thousand colours<br><br>
                                                <img class="fluid aw4" src="static/images/sintesi.jpg" alt="" /><br><br>
                                                It is clear that the photographic technique, although far from being
                                                able to reproduce all the variations in hue that exist, is not far from
                                                what the human eye is able to perceive. A standard print on paper, by
                                                contrast, has considerable limitations, as it is not even able to
                                                reproduce a third of the colors that may be present in a fresco or a
                                                painting or a fifth of those possible in a photograph.
                                            </p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </section>
                            <section id="chapter-2">
                                <b-row>
                                    <b-col md="6">
                                        <h2>Wide CMYK Performance</h2>
                                        <p v-if="language === 'it'">Analizzando la teoria del colore, appare chiaro che
                                            la tecnica e la tecnologia di stampa offset hanno forti limiti nella
                                            riproduzione sia dei colori percepibili dall’occhio umano sia di quelli
                                            risultanti in fotografia o dalle tecniche artistiche in
                                            generale.<br><br>Questi limiti influiscono negativamente sulla qualità di
                                            stampa del libro, unica passione di Trifolio, che ha incentrato proprio
                                            sullo standard dell’alta qualità non solo la filosofia aziendale ma anche il
                                            processo produttivo.<br>Così nel 2009 nasce il progetto AreaW4, uno spazio
                                            di innovazione e sperimentazione tecnologica, un laboratorio di idee e di
                                            esperienze, fortemente voluto da Trifolio per offrire ai propri clienti una
                                            qualità di stampa superiore rispetto alla concorrenza sul
                                            mercato.<br><br>Mentre la tendenza globale dell’innovazione è indirizzata al
                                            contenimento dei costi, alla riduzione dei tempi di lavorazione, alla
                                            semplificazione e all’automatizzazione del processo, con un conseguente
                                            livellamento verso il basso degli standard qualitativi, AreaW4 si muove in
                                            controtendenza e investe sul futuro: abbandona gli standard ISO e le
                                            tendenze imposte dal mercato; dilata i tempi di ricerca e di produzione,
                                            combina la sensibilità umana alle nuove tecnologie e ai materiali
                                            d’avanguardia, oltrepassa sostanzialmente lo standard.<br><br>L’obiettivo
                                            primario di AreaW4 è aumentare il numero di toni (colori) riproducili in
                                            stampa offset utilizzando i quattro pigmenti base CMYK. Allo stato attuale
                                            delle ricerche l’allargamento dello spettro è del 40% se confrontato con lo
                                            standard ISO. Questo incremento permette la riproduzione di toni inesistenti
                                            e irriproducibili con la stampa tradizionale, che garantiscono un sensibile
                                            aumento della risoluzione e della profondità delle immagini stampate.</p>
                                        <p v-else>In analyzing color theory, it seems evident that the technique and
                                            technology of offset printing have severe limitations in the reproduction of
                                            both the colors perceptible by the human eye and of those produced in
                                            photography or by artistic techniques in general.<br><br>
                                            These limitations have a negative effect on the quality of a printed book.
                                            As Trifolio’s mission is to create books to the highest-possible standard,
                                            this critical issue has been at the heart of the company’s development of
                                            its production.<br><br>
                                            In 2009, the company launched the AreaW4 project, a locus of innovation and
                                            technological experimentation, a workshop of ideas and experiences that
                                            Trifolio regards as vital in order to offer its clients a quality of
                                            printing that is superior to that of its competitors.<br><br>
                                            While the market’s general tendency is to direct innovation toward
                                            containment of costs, reduction in production times, and simplification and
                                            automation of the process—with a consequent lowering of the bar in standards
                                            of quality—AreaW4 invests in the future: it has abandoned the ISO standard
                                            and trends set by the market; extended the time for research and production;
                                            and combined human sensibility with new technologies and state-of-the-art
                                            materials to surpass standard production results.<br><br>
                                            The primary objective of AreaW4 is to increase the number of tones (i.e.,
                                            colors) that can be reproduced in offset printing when using the four basic
                                            CMYK pigments. Currently, the spectrum has been extended by 40 percent with
                                            regard to the ISO standard. This extension permits the reproduction of
                                            shades of color that do not exist or cannot be reproduced in traditional
                                            printing, guaranteeing an appreciable increase in the resolution and depth
                                            of the printed images.<br><br>
                                            Wide CMYK Performance is the result of the AreaW4 method, which is a
                                            synthesis of extended performance in four-color printing and an open-minded
                                            approach to the use of techniques and choice of materials in the printing
                                            process.<br><br>
                                            The technique developed by Trifolio is based on methods of conversion and
                                            processing of images (of necessity, RGB files), the use of nonstandard
                                            pigments, and the minimization of margins of error, achieved through
                                            analysis using algorithms derived from multiple readings and careful control
                                            of environmental variables.
                                            AreaW4 is a constantly evolving project, a quality brand in the sector of
                                            offset printing, and a source of pride for the Trifolio company.</p>
                                    </b-col>
                                    <b-col md="6">
                                        <img class="fluid aw4" src="static/images/Paragone_GAMUT_ab_50.jpg" alt="" />
                                    </b-col>
                                </b-row>
                            </section>
                            <section id="chapter-3">
                                <b-row>
                                    <b-col md="12">
                                        <div v-if="language === 'it'">
                                            <h2>Perchè</h2>
                                            <p>Perché scegliere di stampare con AreaW4 printing process di Trifolio?
                                                Perché gli standard sono un punto di partenza, non un traguardo da
                                                raggiungere.</p>
                                        </div>
                                        <div v-else>
                                            <h2>Why</h2>
                                            <p>Why choose to make use of Trifolio’s AreaW4 printing process? Because
                                                standards are a starting point, not a goal to be reached.</p>
                                        </div>
                                        <b-row>
                                            <b-col>
                                                <img class="fluid aw4" src="static/images/Gamut_ISO.jpg" alt="" />
                                                <strong>Gamut ISO</strong>
                                            </b-col>
                                            <b-col>
                                                <img class="fluid aw4" src="static/images/Gamut_AW4.jpg" alt="" />
                                                <strong>Gamut AW4</strong>
                                            </b-col>
                                        </b-row>
                                        <p></p>
                                        <p v-if="language === 'it'">Estensione della gamma tonale, maggior rendimento
                                            nel Gamut e inconsuete emozioni per l’occhio. <strong>Perché è garanzia di
                                                fedeltà superiore</strong>.<br><br>Colori più
                                            <strong>brillanti</strong>, dettagli più <strong>definiti</strong>, immagini
                                            più <strong>profonde</strong>. Perché la stampa di un libro richiede
                                            <strong>passione</strong>. <br><br><strong>E AreaW4 è la risposta</strong>.
                                        </p>
                                        <p v-else>Because it extends the tonal range, yields a wider spectrum of colors,
                                            and provides heightened visual experiences. Because it is a guarantee of
                                            <strong>greater fidelity</strong>, with hues that are <strong>more
                                                brilliant</strong>, details that are <strong>better defined</strong>,
                                            and images that have <strong>greater depth</strong>. Because the printing of
                                            a book requires <strong>passion</strong>.<br><br><strong>And AreaW4 is the
                                                answer</strong>.
                                        </p>
                                    </b-col>
                                </b-row>
                            </section>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    x: false,
    y: true
})
export default {
    name: 'area-w4',
    data () {
        return {
            currentSection: 'w4-home'
        }
    },
    computed: {
        metaDescription () {
            return this.language === 'it' ? 'AreaW4 è un processo di stampa offset ideata da Trifolio per riprodurre fino a 520.000 toni di colore in quadricromia per libri di massima qualità.' : 'Offset printing process developed by Trifolio to reproduce up to 520,000 four-colour tones for high quality books.'
        },
        metaTitle () {
            return this.language === 'it' ? 'AreaW4 - Stampa Superiore' : 'AreaW4 High-Performance Print'
        }
    },
    metaInfo () {
        return {
            title: this.metaTitle,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://trifoliosrl.com/area-w4'
                }
            ],
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                }
            ]
        }
    }
}
</script>
