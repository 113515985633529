<template>
    <div class="calendar-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <myCal @changeDate="updateDate" :cyear="currentYear" :cmonth="currentMonth" />
                    <div v-if="selectedProject" class="detail">
                        <h3 class="selectedDayString"> {{ selectedDayString }}</h3>
                        <p class="workTitle"><span :style="bgc" /> {{ selectedProject.workTitle }}</p>
                        <p class="description">{{ selectedProject.workDescription }}</p>
                        <p class="orderCode">codice ordine : {{ selectedProject.workId }}</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <full-calendar :events="events" :config="config" @day-click="dayClicked" defaultView="month"
                        ref="fullCalendar"></full-calendar>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import myCal from '@/components/myCal'
import VueSwal from 'vue-swal'
import {
    mapGetters
} from 'vuex'
import {
    FETCH_CALENDAR
} from '@/store/actions.type'
import {
    SET_DATE
} from '@/store/mutations.type'
import FullCalendar from 'vue-full-calendar'
import '../../node_modules/fullcalendar/dist/fullcalendar.css'

Vue.use(FullCalendar)
Vue.use(VueSwal)

export default {
    name: 'calendar-view',
    components: {
        myCal
    },
    data () {
        return {
            selectedDay: null,
            selectedDayString: '',
            selectedProject: null,
            bgc: {
                backgroundColor: ''
            },
            config: {
                locale: this.language,
                height: 'auto',
                header: {
                    left: '',
                    center: 'title',
                    right: ''
                }
            },
            events: [],
            months: [{
                it: 'Gennaio',
                en: 'January'
            },
            {
                it: 'Febbraio',
                en: 'February'
            },
            {
                it: 'Marzo',
                en: 'March'
            },
            {
                it: 'Aprile',
                en: 'April'
            },
            {
                it: 'Maggio',
                en: 'May'
            },
            {
                it: 'Giugno',
                en: 'June'
            },
            {
                it: 'Luglio',
                en: 'July'
            },
            {
                it: 'Agosto',
                en: 'August'
            },
            {
                it: 'Settembre',
                en: 'September'
            },
            {
                it: 'Ottobre',
                en: 'October'
            },
            {
                it: 'Novembre',
                en: 'November'
            },
            {
                it: 'Dicembre',
                en: 'December'
            }
            ]
        }
    },
    computed: {
        // lang () {
        //     return this.language
        // },
        currentMonthLabel () {
            return this.language === 'it' ? this.months[this.currentMonth - 1].it : this.months[this.currentMonth - 1].en
        },
        listConfig () {
            const filters = {
                from: this.from,
                to: this.to
            }
            return {
                filters
            }
        },
        ...mapGetters([
            'calendar',
            'currentUserId',
            'currentUserType',
            'currentMonth',
            'currentYear'
        ])
    },
    mounted () {
        this.fetchCalendar()
    },
    watch: {
        calendar () {
            this.parseCalendar()
        }
    },
    methods: {
        monthFromChanged (page) {
            this.$store.commit(SET_DATE, {
                month: page.month,
                year: page.year
            })
        },
        fetchCalendar () {
            this.$store.dispatch(FETCH_CALENDAR, this.listConfig)
        },
        dayClicked (d) {
            var date = this.$moment.utc(d).format('YYYYMMDD')
            var evs = this.events.filter((e) => {
                return this.$moment.utc(date).isBetween(e.start, e.end, null, '[)')
            })
            var dayNumber = this.$moment.utc(d).weekday()
            var content = document.createElement('div')
            if (dayNumber === 0) {
                return this.$swal('We are closed!', '', 'error')
            }
            content.innerHTML = '<a href="mailto:massimo@trifoliosrl.com?subject=Printing request for ' + date + '"><i class="fa fa-envelope"></i> Write Us!</a>'
            if (evs.length && evs[0].customData) {
                if (evs[0].customData.type === 'o') {
                    this.$swal({
                        title: 'This date is busy!',
                        content: content,
                        icon: 'warning'
                    })
                } else {
                    this.bgc.backgroundColor = evs[0].customData.backgroundColor
                    this.selectedProject = evs[0].customData
                }
            } else {
                this.$swal({
                    title: 'This date is free!',
                    content: content,
                    icon: 'success'
                })
            }
        },
        updateDate () {
            var _d = this.currentYear + '-' + this.currentMonth + '-01'
            this.$refs.fullCalendar.fireMethod('gotoDate', this.$moment.utc(_d))
        },
        parseCalendar () {
            for (var d = 0; d < this.calendar.length; d++) {
                var newAtt = {}
                var event = this.calendar[d]
                if (parseInt(event.client) === parseInt(this.currentUserId) || parseInt(this.currentUserType) === 386) {
                    if (event.from && event.to) {
                        newAtt = {
                            allDay: true,
                            editable: false,
                            className: 'event',
                            title: event.title,
                            start: this.$moment.utc(event.from),
                            end: this.$moment.utc(event.to).add(1, 'days'),
                            backgroundColor: event.clientColor,
                            rendering: 'background',
                            customData: {
                                backgroundColor: event.clientColor,
                                type: 'e',
                                clientId: event.client,
                                workId: event.jobRef,
                                workTitle: event.title,
                                workDescription: event.descr,
                                clientName: event.clientName
                            }
                        }
                    }
                } else {
                    newAtt = {
                        allDay: true,
                        editable: false,
                        className: 'busy',
                        rendering: 'background',
                        backgroundColor: '#333333',
                        start: this.$moment.utc(event.from),
                        end: this.$moment.utc(event.to).add(1, 'days'),
                        customData: {
                            type: 'o'
                        }
                    }
                }
                this.events.push(newAtt)
            }
        }
    },
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Calendar | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut',
        link: [
            {
                rel: 'canonical',
                href: 'https://trifoliosrl.com/calendar'
            }
        ]
    }
}
</script>
<style>
#calendar {
    margin-top: 30px;
}

.fc-day-number {
    font-size: 40px;
    display: block;
    padding: 10px 10px !important;
    float: none !important;
}

.fc-day-top {
    text-align: center;
}

.fc table {
    margin: 0;
}

.fc-row .fc-content-skeleton {
    padding: 0;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed .fc-row {
    border-color: transparent;
}

.fc-event-container {
    position: relative;
}

.event {
    border: none;
    padding: 4px 8px;
    color: white !important;
}

.busy {
    height: 2px;
    opacity: .1 !important;
}

.fc-bgevent {
    opacity: .6;
}
</style>
