<template>
    <div id="wall">
        <!-- <img src="static/images/Panoramica_Beauty_wall.jpg" alt=""> -->
        <div v-masonry transition-duration="1s" item-selector=".item" class="masonry-container">
            <div v-masonry-tile class="item" v-bind:key="index" v-for="(item, index) in blocks">
                <a @click="setImage(item)"><img :src="item.img" alt=""></a>
            </div>
        </div>
        <b-modal v-model="modalVideoShow" size="lg" class="video-modal" :centered="centered" :hide-header="hide"
            :hide-footer="hide">
            <vimeo-player ref="player" :player-height="windowHeight" :player-width="windowWidth" :video-id="videoID">
            </vimeo-player>
        </b-modal>
        <b-modal v-model="modalShow" size="lg" :centered="centered" :hide-header="hide" :hide-footer="hide">
            <img v-if="currentImage" :src="currentImage.big" alt="">
        </b-modal>
    </div>
</template>
<script>
import Vue from 'vue'
import {
    VueMasonryPlugin
} from 'vue-masonry'
import _WALLPICS from '../../static/json/wall.json'

Vue.use(VueMasonryPlugin)

export default {
    name: 'WallPage',
    data: function () {
        return {
            currentImage: null,
            modalShow: false,
            modalVideoShow: true,
            centered: true,
            windowHeight: parseInt(window.innerHeight / 1.77),
            windowWidth: window.innerWidth,
            hide: true,
            videoID: '271286002',
            blocks: _WALLPICS
        }
    },
    methods: {
        setImage: function (pic) {
            this.currentImage = pic
            this.modalShow = true
        }
    },
    mounted: function () {
        let that = this
        this.$nextTick(function () {
            window.addEventListener('resize', () => {
                that.windowHeight = window.innerHeight
                that.$redrawVueMasonry()
            })
        })
        this.$redrawVueMasonry()
    },
    metaInfo () {
        return {
            title: this.metaTitle,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://trifoliosrl.com/wall'
                }
            ],
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.metaDescription
                }
            ]
        }
    }
}
</script>
<style scoped>
.item {
    width: 100%;
    cursor: pointer;
}

.item img {
    transition: all .2s;
    -webkit-transition: all .2s;
}

.item img:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    z-index: 9999;
}

.masonry-container {
    width: 100%;
}

@media (min-width: 576px) {
    .item {
        width: 50%;
    }
}

@media (min-width: 768px) {
    .item {
        width: 33.3%;
    }
}

@media (min-width: 992px) {
    .item {
        width: 25%;
    }
}

@media (min-width: 1200px) {
    .item {
        width: 20%;
    }
}

@media (min-width: 1400px) {
    .item {
        width: 10%;
    }
}
</style>
