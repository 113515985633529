<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col">
                <h3>Privacy Policy</h3>
                <p>
                    <span v-if="language === 'it'">
                        Ai sensi dell'art. 13 del d. lgs. n. 196 del 30 giugno 2003 ("Codice Privacy"), la presente
                        informativa descrive le modalità con cui Trifolio S.r.l. tratta i dati personali degli utenti in
                        relazione all'utilizzo del sito www.trifoliosrl.com ( " Il Sito " ).<br><br>
                        <h4>Titolare del trattamento</h4>
                        Il titolare del trattamento dei dati personali relativi al Sito è Trifolio S.r.l., con sede in
                        Verona, in Via dei Peschi 10/A.<br>
                        <h4>Categorie, natura e finalità dei dati trattati</h4>
                        Trifolio S.r.l. tratterà alcuni dati personali degli utenti che interagiscono con i servizi web
                        del Sito.<br>
                        <h4>Dati di navigazione</h4>
                        Si tratta di dati di navigazione che i sistemi informatici acquisiscono automaticamente durante
                        l'utilizzo del Sito, quale l'indirizzo IP, gli indirizzi in notazione URI (Uniform Resource
                        Identifier), nonchè i dettagli delle richieste inviate al server del Sito, e che ne rendono
                        possibile la navigazione. I dati di navigazione potranno altresì essere utilizzati per compilare
                        statistiche anonime che permettono di comprendere l'utilizzo del Sito e di migliorare la
                        struttura dello stesso.<br>
                        Infine, i dati di navigazione potranno eventualmente essere utilizzati per l'accertamento di
                        attività illecite, come in casi di reati informatici, a danno del Sito.<br>
                        <h4>Dati forniti dall'utente</h4>
                        L'eventuale invio di comunicazioni ai contatti indicati sul Sito comporta l'acquisizione
                        dell'indirizzo e-mail e degli ulteriori dati personali contenuti nella comunicazione.<br>
                        <h4>Cookie</h4>
                        Il Sito potrà utilizzare alcune tipologie di cookie. In particolare, il Sito utilizza cookie di
                        sessione, la cui funzione è limitata alla trasmissione di dati identificativi di sessione
                        necessari ai fini di garantire una navigazione efficiente. Altre tipologie di cookie, o
                        tecnologie analoghe, potranno essere di volta in volta impiegate per consentire l'uso del Sito o
                        di specifiche funzionalità. Possono essere utilizzati alcuni cookie persistenti al fine di
                        tracciare la lingua utilizzata dal sistema informatico dell'utente, che potranno comunque essere
                        disattivati in ogni momento. Non sono invece impiegati cookie per l'acquisizione e la
                        trasmissione di dati identificativi personali degli utenti né tracking cookie. Per i dati di
                        sessione e per i dati strettamente necessari al funzionamento del Sito, il conferimento è
                        necessario e non richiede il consenso degli utenti. Il mancato conferimento di questi dati potrà
                        eventualmente comportare l'impossibilità di navigare sul Sito. Per ulteriori informazioni sulla
                        disattivazione e il settaggio dei cookie, gli utenti possono consultare la sezione "Help" del
                        proprio browser o seguire le procedure appositamente previste da quest'ultimo.<br>
                        <h4>Natura facoltativa del conferimento dei dati</h4>
                        Fermo restando quanto indicato in relazione ai dati di navigazione e ai cookie, gli utenti sono
                        liberi di fornire dati personali indicati in contatti e/o comunicazioni con Trifolio S.r.l.. Il
                        loro mancato conferimento può comportare l'impossibilità di ricevere risposte.<br>
                        <h4>Modalità del trattamento</h4>
                        I dati personali sono trattati con strumenti automatizzati, con logiche strettamente correlate
                        alle finalità stesse, e per il periodo di tempo strettamente necessario a conseguire gli scopi
                        per cui sono stati raccolti.<br>
                        Le informazioni raccolte sono registrate in un ambiente sicuro.<br>
                        <h4>Ambito di comunicazione dei dati</h4>
                        I dati personali saranno trattati dal personale incaricato di Trifolio S.r.l. ed eventualmente
                        dai suoi professionisti. I dati personali potranno inoltre essere trattati da terzi, fornitori
                        di servizi esterni (per es., assistenza tecnica), che agiscano per conto o a nome di Trifolio
                        S.r.l., debitamente nominati quali Responsabili del trattamento, e che tratteranno i dati in
                        conformità allo scopo per cui i dati sono stati in origine raccolti.<br>
                        <h4>Diffusione dei dati</h4>
                        I dati personali non sono soggetti a diffusione.<br>
                        <h4>Diritti dell'interessato</h4>
                        La informiamo che il Codice Privacy conferisce agli interessati l'esercizio di specifici
                        diritti.<br>
                        In particolare, in ogni momento, l'interessato può ottenere:<br>
                        "la conferma dell'esistenza o meno di dati personali che lo riguardano, anche se non ancora
                        registrati, e la loro comunicazione in forma intelligibile;<br>
                        "l'indicazione dell'origine dei dati personali, delle finalità e modalità del trattamento nonché
                        della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti
                        elettronici;<br>
                        "l'indicazione degli estremi identificativi del titolare e dei responsabili nonché dei soggetti
                        o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono
                        venirne a conoscenza;<br>
                        "l'aggiornamento, la rettificazione, l'integrazione dei dati, nonché la cancellazione, la
                        trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi
                        quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono
                        stati raccolti o successivamente trattati;<br>
                        "l'attestazione che le operazioni di cui alla lettera d) sono state portate a conoscenza, anche
                        per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o
                        diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego
                        di mezzi manifestamente sproporzionato rispetto al diritto tutelato.<br>
                        L'interessato, ai sensi dell'art. 7 del Codice Privacy, ha diritto altresì di opporsi, per
                        motivi legittimi, al trattamento dei dati personali, anche se pertinenti allo scopo della
                        raccolta, nonché al trattamento di dati personali a fini di invio di comunicazioni promozionali
                        per il compimento di ricerche di mercato. Tutti questi diritti possono essere esercitati
                        scrivendo a: <a href="mailto:trifolio@trifoliosrl.com">trifolio@trifoliosrl.com</a><br>
                        <br>
                        La Privacy Policy del Sito potrà essere soggetta a periodici aggiornamenti.<br><br><br>
                    </span>
                    <span v-else>
                        Pursuant to article 13 of Italian legislative decree no. 196 of 30 June 2003 ("data protection
                        code"), this information notice describes how Trifolio S.r.l. processes the personal data of
                        users when they access the website www.trifoliosrl.com (the "Website").<br>
                        <h4>Data controller</h4>
                        The controller of the personal data pertaining to the Website is Trifolio S.r.l., with
                        registered offices at Via dei Peschi 10/a, Verona - Italy.<br>
                        <h4>Categories, type and purposes of the processed data.</h4>
                        Trifolio S.r.l. shall process selected personal data of users who interact with the Website's
                        services.<br>
                        <h4>Navigation data</h4>
                        These are navigation data that the computer systems acquire automatically during use of the
                        Website, such as the IP address, URI (Uniform Resource Identifier) addresses, together with
                        details of the requests sent to the Website's server, which make navigation possible. Navigation
                        data may also be used to compile anonymous statistics, which make it possible to understand how
                        the Website is used, and to improve the structure thereof.<br>
                        Finally, navigation data may also be used in order to check for any illegal operations, as in
                        the case of cyber crimes, to the detriment of the Website.<br>
                        <h4>Data provided by the user</h4>
                        Any communication sent to the contacts indicated on the Website implies the acquisition of the
                        e-mail address and of the other personal data contained in the communication.<br>
                        <h4>Cookies</h4>
                        The Website may utilise different types of cookies. More specifically, the Website uses session
                        cookies, whose sole function is the transmission of the session identifying data required to
                        guarantee efficient searching on the Website. Other types of cookies, or of similar
                        technologies, may be employed from time to time in order to permit the use of the Website or of
                        certain functions. Specific persistent cookies, which can be deleted at any time, may be used to
                        track the language of the user's computer system. No use shall be made of cookies for the
                        acquisition and transmission of person identifying data, or of tracking cookies. In the case of
                        session data and of those data strictly necessary in order for the Website to function, such
                        data must be necessarily supplied and the users' consent is not required. Failure to supply such
                        data may result in the impossibility of connecting to the Website. For further information
                        regarding the deletion and setting of cookies, users may consult the "Help" section of their own
                        browser, or follow the specific instructions provided by said browser.<br>
                        <h4>Voluntary disclosure of data</h4>
                        Without prejudice to the points made in regard to navigation data and cookies, users are free to
                        supply their personal data in contacts and/or communications with Trifolio S.r.l.. Failure to
                        supply such data may make it impossible to receive a response.<br>
                        <h4>Processing methods</h4>
                        Personal data shall be processed using automated means, on the basis of logics strictly related
                        to the purposes of processing, and for the time strictly necessary to achieve the purposes for
                        which such data have been collected.<br>
                        Collected information shall be stored in a safe place.<br>
                        <h4>Scope of communication of personal data</h4>
                        Personal data shall be processed by the personnel appointed by Trifolio S.r.l., and if necessary
                        by the firm's professionals. Personal data may also be processed by third parties, providers of
                        external services (e.g. technical assistance), acting on behalf or in the name of Trifolio
                        S.r.l. and duly appointed as data processor, and who shall process the data in accordance with
                        the purpose for which the data were originally collected.<br>
                        <h4>Disclosure of data</h4>
                        Personal data shall not be disclosed.<br>
                        <h4>Rights of the data subject</h4>
                        We inform you that the data protection code grants the data subject certain specific rights. In
                        particular, at any moment in time the data subject may obtain:<br>
                        "confirmation of the existence, or otherwise, of the personal data concerning said subject, even
                        if such data have still not been filed, and their communication in an intelligible form;<br>
                        "specification of the origin of the personal data, of the purposes and methods of processing,
                        and of the logic applied in the case of electronic processing;<br>
                        "the identification details of the data controller and of the data processors, and of those
                        persons or categories of persons to whom the personal data may be disclosed, or who may become
                        aware of such personal data;<br>
                        "the updating, rectification and supplementing of data, as well as the deletion, transformation
                        into anonymous form or blocking of any data processed in breach of the law, including those data
                        which do not have to be stored in relation to the purposes for which they were collected or
                        subsequently processed;<br>
                        "certification that the operations as per letter d) above, have been referred, also in regard to
                        their contents, to those to whom the data have been communicated or disclosed, unless fulfilment
                        of such requirement proves impossible or entails the use of means clearly disproportionate to
                        the safeguarded right.<br>
                        Pursuant to article 7 of the data protection code, the data subject is also entitled, with due
                        reason, to object to the processing of personal data, even if it pertains to the purpose of the
                        collection thereof, and also to the processing of personal data for the purpose of sending
                        promotional communications for the completion of market surveys. All of the aforesaid rights may
                        be exercised by writing to: <a
                            href="mailto:trifolio@trifoliosrl.com">trifolio@trifoliosrl.com</a>.<br>
                        The Website's privacy policy may be updated periodically.
                    </span>
                </p>
            </div>
        </div>
        <div class="clearfix separator"></div>
    </div>
</template>
<script>
export default {
    name: 'PrivacyPolicy',
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Privacy | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut',
        link: [
            {
                rel: 'canonical',
                href: 'https://trifoliosrl.com/privacy'
            }
        ],
    }
}
</script>
