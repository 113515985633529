<template>
    <div class="single-portfolio-wrapper text-center" :class="{ dataSheet: isAuthenticated }">
        <div id="bigCarousel"
            :class="{ biggest: biggest, firstSlide: carouselSlide === 0, lastSlide: carouselSlide === commessa.medias.length - 1 }">
            <a @click="toggleCarousel()" href="javascript:;" class="close-single-portfolio"></a>
        </div>
        <div class="single-portfolio-inset" :class="{ loading: loading }">
            <a @click="closePortfolio()" href="javascript:;" class="close-single-portfolio"></a>
            <div v-if="commessa" class="single-portfolio">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h1 class="commessa-title">{{ commessa.title }}</h1>
                        </div>
                    </div>
                    <div class="row align-items-center portfolio-top-section">
                        <div class="col-md-6" v-if="commessa.medias.length">
                            <vimeo-player ref="player" v-if="video" :video-id="video.source" @seeked="videoSeeked">
                            </vimeo-player>
                            <div v-else id="smallCarouselWrapper"
                                :class="{ firstSlide: carouselSlide === 0, lastSlide: carouselSlide === commessa.medias.length - 1 }">
                                <b-carousel id="smallCarousel" style="text-shadow: 1px 1px 2px #333;" controls :interval="0"
                                    @sliding-start="onSlideStart">
                                    <b-carousel-slide @click.native="toggleCarousel"
                                        v-for="(media, index) in commessa.medias" :key="index"
                                        :img-src="mediaPath + media.source"></b-carousel-slide>
                                </b-carousel>
                            </div>
                        </div>
                        <div v-else class="col-md-6">
                            <img class="img-fluid" src="http://placehold.it/1920x900" alt="" />
                        </div>
                        <div class="col-md-6">
                            <h4 v-lang.customer></h4>
                            <p>
                                <i>{{ commessa.client.name || '///' }}</i>
                            </p>
                            <h4 v-lang.publisher></h4>
                            <p>
                                <i>{{ commessa.publisher.name || '///' }}</i>
                            </p>
                            <h4 v-lang.designer></h4>
                            <p>
                                <i>{{ commessa.designer.name || '///' }}</i>
                            </p>
                            <h4 v-lang.manager></h4>
                            <p>
                                <i>{{ commessa.manager.name || '///' }}</i>
                            </p>
                        </div>
                        <p class="commessa-date">{{ commessa.year }}</p>
                    </div>
                    <div class="row portfolio-bottom-section">
                        <div class="col-6 col-md-5 offset-md-1 text-center">
                            <div class="row">
                                <div class="col-md-4">
                                    <h4 v-lang.width></h4>
                                    <p>{{ commessa.techDataSheet.width ? commessa.techDataSheet.width + ' mm' : '///' }}
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <h4 v-lang.height></h4>
                                    <p>{{ commessa.techDataSheet.height ? commessa.techDataSheet.height + ' mm' : '///' }}
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <h4 v-lang.pages></h4>
                                    <p>{{ commessa.techDataSheet.pages ? commessa.techDataSheet.pages : '///' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-5 text-center">
                            <div class="row">
                                <div class="col-md-6">
                                    <h4 v-lang.paper></h4>
                                    <p>
                                        {{ commessa.materials.carta_interno.nome || '///' }}
                                        <br>
                                        {{ commessa.materials.grammaturaInterno.nome || '///' }}
                                    </p>
                                </div>
                                <div class="col-md-6 text-left">
                                    <h4 v-lang.binding></h4>
                                    <p v-if="language === 'it'">
                                        {{ commessa.materials.note || '///' }}
                                    </p>
                                    <p v-else>
                                        {{ commessa.materials.note_en || '///' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isAdmin" class="container-fluid">
                    <h3 v-lang.details></h3>
                    <div class="row text-left">
                        <div class="col">
                            <p><strong v-lang.title></strong> {{ commessa.title }}</p>
                        </div>
                        <div v-if="language === 'it'" class="col">
                            <p><strong v-lang.code></strong> {{ commessa.code || '///' }}</p>
                        </div>
                        <div v-if="language === 'it'" class="col">
                            <p><strong v-lang.progressive></strong> {{ commessa.progr ? commessa.progr : '///' }}</p>
                        </div>
                        <div class="col">
                            <p><strong v-lang.year></strong> {{ commessa.year || '///' }}</p>
                        </div>
                    </div>
                    <div class="row text-left">
                        <div class="col-md-3">
                            <p><strong v-lang.artist></strong> {{ commessa.artista.name || '///' }}</p>
                        </div>
                        <div class="col-md-3">
                            <p><strong v-lang.customer></strong> {{ commessa.client.name || '///' }}</p>
                        </div>
                        <div class="col-md-3">
                            <p><strong v-lang.designer></strong> {{ commessa.designer.name || '///' }}</p>
                        </div>
                        <div class="col-md-3">
                            <p><strong v-lang.publisher></strong> {{ commessa.publisher.name || '///' }}</p>
                        </div>
                        <div class="col-md-3">
                            <p><strong v-lang.museum></strong> {{ commessa.museum.name || '///' }}</p>
                        </div>
                        <div class="col-md-3">
                            <p><strong v-lang.gallery></strong> {{ commessa.gallery.name || '///' }}</p>
                        </div>
                    </div>
                    <div v-if="commessa.categories.length > 0">
                        <div class="row text-left" v-for="(category, index) in commessa.categories" :key="index">
                            <div v-if="language === 'it'" class="col-md-3">
                                <p><strong v-lang.category></strong> {{ category.name || '///' }}</p>
                            </div>
                            <div class="col-md-3">
                                <p><strong v-lang.edition></strong> {{ category.tiratura || '///' }}</p>
                            </div>
                            <div class="col-md-3">
                                <p><strong v-lang.notes></strong> {{ category.note || '///' }}</p>
                            </div>
                        </div>
                    </div>
                    <h3 v-lang.dataSheet></h3>
                    <div class="row text-left">
                        <div class="col-md-3">
                            <p><strong v-lang.format></strong></p>
                        </div>
                        <div class="col">
                            <p><strong v-lang.width></strong>
                                {{ commessa.techDataSheet.width ? commessa.techDataSheet.width + ' mm' : '///' }}</p>
                        </div>
                        <div class="col">
                            <p><strong v-lang.height></strong>
                                {{ commessa.techDataSheet.height ? commessa.techDataSheet.height + ' mm' : '///' }}</p>
                        </div>
                        <div class="col">
                            <p><strong v-lang.pages></strong>
                                {{ commessa.techDataSheet.pages ? commessa.techDataSheet.pages + ' mm' : '///' }}</p>
                        </div>
                        <div class="col">
                            <p>
                                <strong v-lang.bookType></strong>
                                <span v-if="language === 'it'">{{ commessa.techDataSheet.bookType.nome || '///' }}</span>
                                <span v-else>{{ commessa.techDataSheet.bookType.nomeEn || '///' }}</span>
                            </p>
                        </div>
                    </div>
                    <div v-if="commessa.techDataSheet.segnatures.length > 0">
                        <div class="row text-left">
                            <div class="col-md-3">
                                <p><strong v-lang.signature></strong></p>
                            </div>
                            <div class="col" v-for="(segnature, index) in commessa.techDataSheet.segnatures" :key="index">
                                <p>{{ segnature.name || '///' }} {{ segnature.qta || '///' }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="commessa.techDataSheet.images.length > 0">
                        <div class="row text-left">
                            <div class="col-md-3">
                                <p><strong v-lang.imagesType></strong></p>
                            </div>
                            <div class="col" v-for="(image, index) in commessa.techDataSheet.images" :key="index">
                                <p>{{ image.name || '///' }} {{ image.qta || '///' }}</p>
                            </div>
                        </div>
                    </div>
                    <h3 v-lang.materials></h3>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">
                                            <h4 v-lang.paperType></h4>
                                        </th>
                                        <th scope="col">
                                            <h4 v-lang.grm></h4>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-lang.internalFirst></td>
                                        <td>{{ commessa.materials.carta_interno.nome || '///' }}</td>
                                        <td>
                                            {{ commessa.materials.grammaturaInterno.nome ?
                                                commessa.materials.grammaturaInterno.nome : '///' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-lang.internalSecond></td>
                                        <td>{{ commessa.materials.carta_interno1.nome || '///' }}</td>
                                        <td>
                                            {{ commessa.materials.grammaturaInterno1.nome ?
                                                commessa.materials.grammaturaInterno1.nome : '///' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-lang.internalThird></td>
                                        <td>{{ commessa.materials.carta_interno2.nome || '///' }}</td>
                                        <td>
                                            {{ commessa.materials.grammaturaInterno2.nome ?
                                                commessa.materials.grammaturaInterno2.nome : '///' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-lang.flyleaf></td>
                                        <td>{{ commessa.materials.carta_risguardi.nome || '///' }}</td>
                                        <td>
                                            {{ commessa.materials.grammaturaRisguardi.nome ?
                                                commessa.materials.grammaturaRisguardi.nome : '///' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-lang.cover></td>
                                        <td>{{ commessa.materials.carta_copertina.nome || '///' }}</td>
                                        <td>
                                            {{ commessa.materials.grammaturaCopertina.nome ?
                                                commessa.materials.grammaturaCopertina.nome : '///' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-lang.dustJacket></td>
                                        <td>{{ commessa.materials.carta_sovracopertina.nome || '///' }}</td>
                                        <td>
                                            {{ commessa.materials.grammaturaSovracopertina.nome ?
                                                commessa.materials.grammaturaSovracopertina.nome : '///' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" colspan="3">
                                            <h4 v-lang.bindery></h4>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-lang.plastification></td>
                                        <td>{{ commessa.materials.plastificazione.name || '///' }}</td>
                                    </tr>
                                    <tr>
                                        <td v-lang.cardboard></td>
                                        <td>{{ commessa.materials.legatoria_cartone.nome || '///' }}</td>
                                    </tr>
                                    <tr>
                                        <td v-lang.canvas></td>
                                        <td>{{ commessa.materials.legatoria_tela.nome || '///' }}</td>
                                    </tr>
                                    <tr>
                                        <td v-lang.shearing></td>
                                        <td>{{ commessa.materials.legatoria_trancia.nome || '///' }}</td>
                                    </tr>
                                    <tr>
                                        <td v-lang.case></td>
                                        <td>{{ commessa.materials.astuccio.nome || '///' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="parseInt(currentUserType) === 386">
                        <h3 v-lang.providers></h3>
                        <div class="row text-left">
                            <div class="col-md-3">
                                <p>
                                    <strong v-lang.photolithography></strong>
                                    {{ commessa.providers.providerFotolito.name || '///' }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p>
                                    <strong v-lang.test></strong>
                                    {{ commessa.providers.providerProve.name || '///' }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p>
                                    <strong v-lang.film></strong>
                                    {{ commessa.providers.providerPellicole.name || '///' }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p>
                                    <strong>CTP</strong>
                                    {{ commessa.providers.providerCtp.name || '///' }}
                                </p>
                            </div>
                        </div>
                        <div class="row text-left">
                            <div class="col-md-3">
                                <p>
                                    <strong v-lang.print></strong>
                                    {{ commessa.providers.providerStampa.name || '///' }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p>
                                    <strong v-lang.bindery></strong>
                                    {{ commessa.providers.providerLegatoria.name || '///' }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p>
                                    <strong v-lang.papermaking></strong>
                                    {{ commessa.providers.providerCartotecnica.name || '///' }}
                                </p>
                            </div>
                        </div>
                        <h3 v-lang.archive></h3>
                        <div class="row text-left">
                            <div class="col">
                                <p>
                                    <strong v-lang.paperbackQty></strong>
                                    {{ commessa.archive.copie_brossura || '///' }}
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    <strong v-lang.cardboardQty></strong>
                                    {{ commessa.archive.copie_cartone || '///' }}
                                </p>
                            </div>
                            <div class="col">
                                <p>
                                    <strong v-lang.shelf></strong>
                                    {{ commessa.archive.scaffale || '///' }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <span class="down-arrow"><i class="arrow down"></i></span>
                </div>
                <div class="portfolios-navigation" v-if="commesseNavigator">
                    <a href="javascript:;" :class="{ disabled: !commesseNavigator.prev }"
                        @click="gotoPortfolio(commesseNavigator.prev)"> &#8592; </a>
                    <a href="javascript:;" :class="{ disabled: !commesseNavigator.next }"
                        @click="gotoPortfolio(commesseNavigator.next)"> &#8594; </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import {
    mapGetters
} from 'vuex'
import store from '@/store'
import {
    FETCH_COMMESSA
} from '@/store/actions.type'
import {
    MEDIA_PATH
} from '@/common/config'
import vimeoPlayer from 'vue-vimeo-player'

Vue.use(vimeoPlayer)

export default {
    name: 'SinglePortfolio',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            loading: false,
            portfolioID: this.id,
            video: '',
            biggest: false,
            mediaPath: MEDIA_PATH,
            carouselSlide: 0
        }
    },
    beforeRouteEnter (to, from, next) {
        Promise.all([
            store.dispatch(FETCH_COMMESSA, to.params.id)
        ]).then(() => {
            next()
        })
    },
    computed: {
        isAdmin () {
            return (this.isAuthenticated && (this.currentUserId === this.commessa.artista.id || this.currentUserId === this.commessa.designer.id || this.currentUserId === this.commessa.manager.id || this.currentUserId === this.commessa.publisher.id || this.currentUserId === this.commessa.museum.id || this.currentUserId === this.commessa.gallery.id)) || parseInt(this.currentUserType) === 386
        },
        ...mapGetters([
            'currentUserId',
            'currentUserType',
            'commessa',
            'commesse',
            'isAuthenticated'
        ]),
        commesseNavigator () {
            if (this.commesse && this.commesse.length > 1) {
                let commesseIds = this.commesse.map(c => {
                    return c.id
                })
                let currentIndex = commesseIds.indexOf(this.id)
                let prevCommessa = commesseIds[currentIndex - 1]
                let nextCommessa = commesseIds[currentIndex + 2]
                let navigator = {
                    prev: prevCommessa,
                    next: nextCommessa
                }
                return navigator
            }
            return false
        }
    },
    created: function () {
        this.getPortfolio()
    },
    methods: {
        videoSeeked: function () {
            this.$refs.player.pause()
            this.$refs.player.player.setCurrentTime(1)
        },
        toggleCarousel: function () {
            this.biggest = !this.biggest
            if (this.biggest) {
                document.getElementById('bigCarousel').appendChild(document.getElementById('smallCarousel'))
            } else {
                document.getElementById('smallCarouselWrapper').appendChild(document.getElementById('smallCarousel'))
            }
        },
        getPortfolio: function () {
            this.video = this.commessa.medias.filter(function (m) {
                return m.type === 'VIDEO'
            })[0]
        },
        // filterData: function () {
        //   for (var i = 0; i < this.configs.length; i++) {
        //     if (parseInt(this.configs[i].id) === parseInt(this.commessa.materials.carta_interno)) this.commessa.materials.carta_interno = this.configs[i].name
        //     if (parseInt(this.configs[i].id) === parseInt(this.commessa.materials.carta_risguardi)) this.commessa.materials.carta_risguardi = this.configs[i].name
        //     if (parseInt(this.configs[i].id) === parseInt(this.commessa.materials.carta_copertina)) this.commessa.materials.carta_copertina = this.configs[i].name
        //   }
        //   for (var l = 0; l < this.contacts.length; l++) {
        //     if (parseInt(this.contacts[l].id) === parseInt(this.commessa.publisher)) this.commessa.publisher = this.contacts[l].name
        //     if (parseInt(this.contacts[l].id) === parseInt(this.commessa.designer)) this.commessa.designer = this.contacts[l].name
        //   }
        // },
        onSlideStart: function (slide) {
            this.carouselSlide = slide
        },
        closePortfolio: function () {
            this.$router.push({
                name: 'Portfolio',
                params: this.$router.currentRoute.params
            })
        },
        gotoPortfolio (id) {
            if (id) {
                this.loading = true
                this.$router.push({
                    name: 'Single',
                    params: {
                        id: id
                    }
                })
                store.dispatch(FETCH_COMMESSA, id).then(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
            }
        }
    },
    metaInfo: {
        title: 'Trifolio Srl',
        titleTemplate: '%s - Commessa | Stampa libri d’arte e fotografia, catalogue raisonne, fine art printing, special prepress, large gamut'
    }
}
</script>
<style>
.loading {
    opacity: .6;
}

iframe {
    width: 100% !important;
    min-height: 480px;
}

.firstSlide .carousel-control-prev {
    display: none;
}

.lastSlide .carousel-control-next {
    display: none;
}

.portfolios-navigation a {
    font-size: 20px;
    margin: 15px;
}

.portfolios-navigation a:hover {
    text-decoration: none;
}

.portfolios-navigation a.disabled {
    cursor: not-allowed;
    opacity: .3;
}
</style>
